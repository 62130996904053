import api from "../../../services/api";


// create User Account
export const createUserAccountApi = (data)=> {
    return api.post('auth/signup',{
        full_name : data.fullName,
        email : data.email,
        password : data.password,
        c_password : data.cPassword,
    })
}


// User authentication
export const userAuth = (data) => {
    return api.post('user/login', {
        email: data.email,
        password: data.password
    })
    .then(res => res)     
    .catch(err => err)
}



// GET User 
export const getUser = () => {
    return api.get(
        'user/profile', 
        {})
    .then(res => res)     
    .catch(err => err)
}

// Update Full Name 
export const updateFullNameApi = (data) => {
    return api.post('user/update',{
        full_name: data.userData,
    })
}


//request to change Password with OTP if user auth
export const changePasswordApi=(data)=>{
   return api
    .post('auth/reset-password',{
      password: data.password,
      c_password: data.password,
      otp : data.otp
    })
  }

//change password if user is not auth
export const changePasswordWithoutAuthApi=(data)=>{
    return api
    .post('auth/change-password',{
      password : data.password,
      c_password : data.c_password,
      email : data.email
    })
  }

// Verify OTP without AUTH
export const verifyOTPApi=(data)=>{
   return api
    .post('auth/verify-otp',{
      otp: data.otp,
      email: data.email,
      otp_type: data.otp_type
    })
  }


//Request for OTP on email if user authStatus
export const requestOtpApi = () => {
  return api
    .get('auth/request-otp')
}


//forget Password from email address
export const forgetPasswordApi = (data) => {
    return api
    .post('auth/forgot-password', {
        email: data.email,
        type: data.type
    })
}


  