import { combineReducers } from "redux";
import { loaderReducer } from "./reducers/loaderReducer";
import { userReducer } from "./reducers/userReducer";
import {highChartReducer} from "./reducers/highChartReducer"

const reducers = combineReducers({
    user : userReducer,
    loader: loaderReducer,
    highchart:highChartReducer
})

export default reducers;