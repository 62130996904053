import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { Formik, Form, ErrorMessage } from "formik"
import * as Yup from "yup";

// CSS
import './Market.css'

// Action
import { getCurrencyFilter, getHighChartData, getMarketSubCategories, getQuarterFilter, getYearFilter, unsetChartData, unsetCurrencyFilter, unsetQuarterFilter, unsetYearFilter } from '../../redux/action/highChartAction';
import { setButtonLoader } from '../../redux/action/loaderAction';

// Components
import CircularProgress from '@mui/material/CircularProgress';
import Highchart from '../../Components/Chart/Highchart';
import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer/Footer';

const Market = () => {
    const dispatch = useDispatch()
    const Params = useParams();
    // for chart form    
    const formRef = useRef()
    const FilterSchema = Yup.object().shape({
        strategy : Yup.string().required("Please select Strategy"), 
        currency: Yup.string().required("Please select Contract"),
        year: Yup.string().required("Please select Year"),
        quarter: Yup.string().required("Please select Month"),
    });
    // get data
    const isLoading = useSelector((state)=>state.loader.buttonLoader)
    const marketSubCategories = useSelector((state) => state.highchart.marketSubCategories)
    const currencyList = useSelector((state) => state.highchart.currencyFilter)
    const yearList = useSelector((state) => state.highchart.yearFilter)
    const quarterList = useSelector((state)=>state.highchart.quarterFilter)
    const [selectedCategory, setSelectedCategory] = useState()
    const [strategyType, setstrategyType] = useState(null)
    useEffect(() => {
        formRef.current.handleChange("strategy")("")
        setSelectedCategory("")
        setstrategyType(Params.marketCategory)
        dispatch(unsetChartData())
    }, [Params, dispatch])
    
    // fetch market sub categories
    useEffect(() => {
        if(marketSubCategories.length <= 0) {
            dispatch(getMarketSubCategories())
        }
    }, [dispatch, marketSubCategories])
    
    // Fetch currency list
    useEffect(() => {
        if(selectedCategory !== "" && selectedCategory !== null && selectedCategory !== undefined) {
            let data = {
                category: strategyType,//  market category
                subCategory: selectedCategory, // market sub category
            }
            // unset list data
            dispatch(unsetCurrencyFilter())
            dispatch(unsetYearFilter())
            dispatch(unsetQuarterFilter())
            // unset form inputs
            formRef.current.handleChange("currency")("")
            formRef.current.handleChange("year")("")
            formRef.current.handleChange("quarter")("")
            
            dispatch(getCurrencyFilter(data))
        } else {
            // unset form inputs
            formRef.current.handleChange("currency")("")
            formRef.current.handleChange("year")("")
            formRef.current.handleChange("quarter")("")
            dispatch(unsetCurrencyFilter())
            dispatch(unsetYearFilter())
            dispatch(unsetQuarterFilter())
        }
    }, [dispatch, selectedCategory, strategyType])
    
    // handle currency change
    const handleCurrencyChange = (value) => {
        let data = {
            category: Params.marketCategory,
            subCategory: selectedCategory,
            currency : value,
        }
        // unset list data 
        dispatch(unsetYearFilter())
        dispatch(unsetQuarterFilter())
        // unset form inputs
        formRef.current.handleChange("year")("")
        formRef.current.handleChange("quarter")("")
        dispatch(getYearFilter(data))
    }
    
    // handle year change 
    const handleYearChange = (value) => {
        const data = {
            category: Params.marketCategory,
            subCategory: selectedCategory,
            currency : formRef.current?.values?.currency,
            year : value
        }
        // unset list data
        dispatch(unsetQuarterFilter())
        // unset form inputs
        formRef.current.handleChange("quarter")("")
        dispatch(getQuarterFilter(data))
    }
    // for select input
    const customStyles = {
        menuList: (styles, state) => {
            return {
                ...styles,
                maxHeight: 180,
            };
        },
        option: (provided, state) => {
            return {
                ...provided,
                "&:hover": {
                    color : "black",
                    backgroundColor: "#E8E8E8"
                },
                backgroundColor: state.isSelected && "#352E57" 
            };
        },
        control: (base, state) => ({
            ...base,
            '&:hover': { borderColor: '#352E57' }, // border style on hover
            border: '1px solid lightgray', // default border color
            boxShadow: 'none', // no box-shadow
        }),
    };

    return (
        <>
            <Navbar />
            <div className="my_assetsfilter container-fluid px-3 px-md-5 ">
                <div className="row dropdown-highchart">
                    <div className="col-lg-4 col-sm-12 assetsfilter_dropdown pl-3 ">
                        <h4 className='text-capitalize pt-4 pb-3'>{Params.marketCategory.replace("-", " ")}</h4>
                        
                        <Formik
                            innerRef={formRef}
                            enableReinitialize
                            initialValues={{
                                strategy : selectedCategory,
                                directory: `${Params.marketCategory}/${selectedCategory}`,
                                currency: "",
                                year: "",
                                quarter: ""
                            }}
                            validationSchema={FilterSchema}
                            onSubmit={(values) => {
                                dispatch(setButtonLoader(true));
                                dispatch(getHighChartData(values))
                            }}
                        >
                            {props => (
                                <div>
                                    <Form>
                                        <div className="row mb-4 pt-1">
                                            <div className="col">
                                                <Select
                                                    value={
                                                        marketSubCategories.filter(function (marketSubCategories) {
                                                            return marketSubCategories.slug === selectedCategory;
                                                        })
                                                    }
                                                    getOptionValue={options => options.slug}
                                                    name="strategy"
                                                    onChange={(selectedOption) => {
                                                        setSelectedCategory(selectedOption.slug)
                                                        dispatch(unsetChartData())
                                                    }}
                                                    options={marketSubCategories}
                                                    placeholder="Select Strategy"
                                                    // Hide  intercommodity-spread category in softs
                                                    filterOption={(option) => 
                                                        Params.marketCategory.replace("-", " ") === "softs" ?  
                                                            option.value !== "intercommodity-spread" 
                                                        :
                                                        Params.marketCategory === "money-markets" 
                                                        || 
                                                        Params.marketCategory === "bonds" ?
                                                            option.value !== "contract" 
                                                        : 
                                                            option.value !== null
                                                    }
                                                    
                                                    styles={customStyles}
                                                />
                                                <ErrorMessage
                                                    component="p"
                                                    name="strategy"
                                                    className="invalid-feedback mb-0"
                                                    style={{display:"block"}}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <div className="col">
                                                <Select
                                                    id="currency"
                                                    name="currency"
                                                    onChange={(selectedOption) => { 
                                                        handleCurrencyChange(selectedOption.id) 
                                                        props.handleChange("currency")(selectedOption.id) 
                                                        dispatch(unsetChartData())
                                                    }}
                                                    options={currencyList}
                                                    placeholder="Select Contract"
                                                    getOptionValue={options => options.id}
                                                    value={
                                                        currencyList.filter(function (currencyList) {
                                                            return currencyList.id === props.values.currency;
                                                        })
                                                    }
                                                    styles={customStyles}
                                                />
                                                <ErrorMessage
                                                    component="p"
                                                    name="currency"
                                                    className="invalid-feedback mb-0"
                                                    style={{display:"block"}}
                                                />
                                            </div>
                                        </div>

                                        <div className="row mb-4">
                                            <div className="col">
                                                <Select
                                                    id="year"
                                                    name="year"
                                                    onChange={(selectedOption) => { 
                                                        handleYearChange(selectedOption.label)
                                                        props.handleChange("year")(selectedOption.label) 
                                                        dispatch(unsetChartData())
                                                    }}
                                                    options={yearList}
                                                    placeholder="Select Year"
                                                    getOptionValue={options => options.id}
                                                    value={
                                                        yearList.filter(function (yearList) {
                                                            return yearList.label === props.values.year;
                                                        })
                                                    }
                                                    styles={customStyles}
                                                />
                                                <ErrorMessage
                                                    component="p"
                                                    name="year"
                                                    className="invalid-feedback mb-0"
                                                    style={{display:"block"}}
                                                />
                                            </div>
                                        </div>

                                        <div className="row mb-4">
                                            <div className="col">
                                                <Select
                                                    id="quarter"
                                                    name="quarter"
                                                    onChange={(selectedOption) => { 
                                                        props.handleChange("quarter")(selectedOption.label) 
                                                        dispatch(unsetChartData())
                                                    }}
                                                    options={quarterList}
                                                    placeholder="Select Month"
                                                    getOptionValue={options => options.id}
                                                    value={
                                                        quarterList.filter(function (quarterList) {
                                                            return quarterList.label === props.values.quarter;
                                                        })
                                                    }
                                                    styles={customStyles}
                                                />
                                                <ErrorMessage
                                                    component="p"
                                                    name="quarter"
                                                    className="invalid-feedback mb-0"
                                                    style={{display:"block"}}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col col-md-12 col-sm-12 col-xl-12">
                                                <button type="submit" className="btn btn-default btn-block py-2 rounded-0 bg-FF6400 text-light d-flex align-items-center justify-content-center" disabled={isLoading ? (true) : (null)} > {isLoading && <CircularProgress size={18} color="inherit" className="mr-2" />} Submit</button>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            )}
                        </Formik>
                    </div>
                    <div className="col-lg-8 col-sm-12 assetsfilter_highchart d-flex justify-content-end">
                        {isLoading && <CircularProgress className="progress_loader" />}
                        <Highchart selectedStrategy={selectedCategory}/>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Market;