import React,{useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
// for toastify
import 'react-toastify/dist/ReactToastify.css';
// Import css
import './App.css';
// API'S AND ACTIONS
import { getUser } from './redux/action/userActions';

//  Import Components
import Auth from './Pages/Auth/Auth.js'
import Home from './Pages/Home/Home'
import ProfilePersonalInfo from './Pages/Profile/ProfilePersonalInfo'
import Billing from './Pages/Billing/Billing'
import Subscription from './Pages/Subscription/Subscription';
import Market from './Pages/Market/Market';
import Loader from './Components/Loader/Loader';
import ChangeAccountPassword from './Pages/ChangePassword/ChangeAccountPassword';
import ProfileInfo from './Components/ProfileInfo/ProfileInfo';
import ChangePasswordForm from './Components/ChangePasswordForm/ChangePasswordForm';
import ChangePasswordOTP from './Components/Auth/ChangePasswordOTP';
import EditProfileDetails from './Components/EditProfileDetails/EditProfileDetails';
import LoginForm from './Components/Auth/LoginForm';
import SignUpForm from './Components/Auth/SignUpForm';
import VerifyEmail from './Components/Auth/VerifyEmail';
import ForgetForm from './Components/Auth/ForgetForm';
import ResetForm from './Components/Auth/ResetForm';

export default function App() {
  const dispatch = useDispatch()
  // GET USER 
  const userAuth = useSelector((state) => state.user.authStatus)
  
  const [isScreenLoading, setIsScreenLoading] = useState(true)

  useEffect(() => {
    let AllMacroData = localStorage.getItem("MacroCodexAuthToken");
    if (AllMacroData) {
      dispatch(getUser())
    } else {
      setIsScreenLoading(false)
    }
  }, [dispatch])

  useEffect(() => {
    if(userAuth === true){
      setIsScreenLoading(false)
    }
  }, [userAuth])
  
  return (
    <>
      {!isScreenLoading ? (
        <BrowserRouter>
          <Routes>
            {userAuth ? (
              <>
                <Route path="/" element={<Navigate to="/auth" />} />
                <Route path="/auth" element={<Auth />}>
                  <Route index element={<ProfileInfo/>} />
                  <Route path="sign-up" element={<SignUpForm/>} />
                  <Route path="verify-email/:type" element={<VerifyEmail/>} />
                  <Route path="forget-password" element={<ForgetForm/>} />
                  <Route path="reset-password" element={<ResetForm/>} />
                </Route>
                <Route path="/dashboard" element={<Home />} />
                <Route path="/profileInfo" element={<ProfilePersonalInfo />} >
                  <Route index element={<ProfileInfo/>} />
                  <Route path="change-password" element={<ChangePasswordForm/>} />
                  <Route path="verify-otp" element={<ChangePasswordOTP/>} />
                  <Route path="edit-profile" element={<EditProfileDetails/>} />
                </Route>
                <Route path="/subscription/:planId" element={<Subscription />} />
                <Route path="/billing" element={<Billing />} />
                <Route path="/markets/:marketCategory" element={<Market />} />
                <Route path="/change-password" element={<ChangeAccountPassword/>} />
              </>
            ) : (
              <>
                <Route path="/" element={<Navigate to="/auth" />} />
                <Route path="/auth" element={<Auth />}>
                  <Route index element={<LoginForm/>} />
                  <Route path="sign-up" element={<SignUpForm/>} />
                  <Route path="verify-email/:type" element={<VerifyEmail/>} />
                  <Route path="forget-password" element={<ForgetForm/>} />
                  <Route path="reset-password" element={<ResetForm/>} />
                </Route>
              </>
            )}
          </Routes>
        </BrowserRouter>
      ) : (
        <Loader />
      )}
    </>
  )
}
