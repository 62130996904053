export const GET_MARKET_CATEGORIES = "GET_MARKET_CATEGORIES"
export const SET_MARKET_CATEGORIES = "SET_MARKET_CATEGORIES"
export const GET_HIGHCHART_DATA = "GET_HIGHTCHART_DATA";
export const SET_HIGHCHART_DATA = 'SET_HIGHCHART_DATA';
export const GET_CURRENCY_FILTER = "GET_CURRENCY_FILTER";
export const SET_CURRENCY_FILTER = "SET_CURRENCY_FILTER";
export const GET_YEAR_FILTER = "GET_YEAR_FILTER";
export const SET_YEAR_FILTER = "SET_YEAR_FILTER";
export const GET_QUARTER_FILTER = "GET_QUARTER_FILTER";
export const SET_QUARTER_FILTER = "SET_QUARTER_FILTER";
export const GET_MARKET_SUB_CATEGORIES = "GET_MARKET_SUB_CATEGORIES";
export const SET_MARKET_SUB_CATEGORIES = "SET_MARKET_SUB_CATEGORIES";
export const UNSET_CURRENCY_FILTER = "UNSET_CURRENCY_FILTER";
export const UNSET_YEAR_FILTER = "UNSET_YEAR_FILTER";
export const UNSET_QUARTER_FILTER = "UNSET_QUARTER_FILTER";
export const UNSET_CHART_DATA = "UNSET_CHART_DATA";


export const getMarketCategories = () => {
    return {
        type: GET_MARKET_CATEGORIES
    }
}

export const setMarketCategories = (marketData) => {
    return {
        type: SET_MARKET_CATEGORIES,
        marketData
    }
}

export const getMarketSubCategories = () => {
    return {
        type: GET_MARKET_SUB_CATEGORIES
    }
}

export const setMarketSubCategories = (payload) => {
    return {
        type: SET_MARKET_SUB_CATEGORIES,
        payload
    }
}

export const getHighChartData = (data) => {
    return {
        type: GET_HIGHCHART_DATA,
        data
    }
}

export const setHighChartData = (data) => {
    return {
        type: SET_HIGHCHART_DATA,
        data
    };
};

export const getCurrencyFilter = (data) => {
    return {
        type: GET_CURRENCY_FILTER,
        data
    }
}

export const setCurrencyFilter = (currrencyData) => {
    return {
        type: SET_CURRENCY_FILTER,
        currrencyData
    }
}

export const unsetCurrencyFilter = () => {
    return {
        type: UNSET_CURRENCY_FILTER
    }
}

export const getYearFilter = (data) => {
    return {
        type: GET_YEAR_FILTER,
        data
    }
}

export const setYearFilter = (yearData) => {
    return {
        type: SET_YEAR_FILTER,
        yearData
    }
}

export const unsetYearFilter = () => {
    return {
        type: UNSET_YEAR_FILTER
    }
}

export const getQuarterFilter = (data) => {
    return {
        type: GET_QUARTER_FILTER,
        data
    }
}

export const setQuarterFilter = (quarterData) => {
    return {
        type: SET_QUARTER_FILTER,
        quarterData
    }
}

export const unsetQuarterFilter = () => {
    return {
        type: UNSET_QUARTER_FILTER
    }
}

export const unsetChartData = () => {
    return {
        type: UNSET_CHART_DATA
    }
}