import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

//redux action
import { requestOTP, unsetOtpSentSuccess } from '../../redux/action/userActions';
import { setButtonLoader } from '../../redux/action/loaderAction';

// COMPONENTS
import CircularProgress from '@mui/material/CircularProgress';
import {  Link, useNavigate } from 'react-router-dom';

export default function ChangePasswordForm(props) {
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isRePasswordVisible, setIsRePasswordVisible] = useState(false)
        
    const isLoading = useSelector((state)=>state.loader.buttonLoader)
    
    const otpSentSuccess = useSelector((state)=>state.user.otpSentSuccess)

    const ChangeResetFormSchema = Yup.object().shape({
        password: Yup.string()
            .min(8, "Password must be 8 characters at minimum")
            .required("Password is required"),            
        rePassword: Yup.string()
            .required('Confirm Password is required')
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
    });

    useEffect(() => {
      if(otpSentSuccess) {
        dispatch(unsetOtpSentSuccess())
        navigate("/profileInfo/verify-otp")
      }
    }, [dispatch, navigate, otpSentSuccess])
    

    return (
        <>
            <div className="row mt-4 align-items-center">
                <div className="col-lg-4 ml-3">
                    <h3 className="mb-4">Change Password </h3>
                    <p>Please enter new 8 Character password</p>
                    <Formik
                        initialValues={{ 
                            password: "",
                            rePassword:""
                        }}
                        validationSchema={ChangeResetFormSchema}
                        onSubmit={(values) => {
                            dispatch(setButtonLoader(true));
                            dispatch(requestOTP(values.password))
                        }}
                    >
                        {(props) => (
                            <Form>
                                <div className="form-group">
                                    <label htmlFor="Password">New Password</label>
                                    <div className="positionRelative">
                                        <Field 
                                            type={isPasswordVisible?("text"):("password")}
                                            name="password" 
                                            className={`form-control ${props.touched.password && props.errors.password ? "is-invalid" : ""}`}
                                            placeholder="Enter 8 Digit Password"
                                        />
                                        <ErrorMessage
                                            component="div"
                                            name="password"
                                            className="invalid-feedback"
                                        />

                                        <span className="eyeIconWrapper">
                                            {isPasswordVisible?(
                                                <i className="far fa-eye" onClick={()=>setIsPasswordVisible(!isPasswordVisible)}></i>
                                            ):(
                                                <i className="far fa-eye-slash" onClick={()=>setIsPasswordVisible(!isPasswordVisible)} ></i>
                                            )}
                                        </span>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="Password">Re-enter Password</label>
                                    <div className="positionRelative">
                                        <Field 
                                            type={isRePasswordVisible?("text"):("password")} 
                                            name="rePassword"
                                            className={`form-control ${props.touched.rePassword && props.errors.rePassword ? "is-invalid" : ""}`} 
                                            placeholder="Re-enter 8 Digit Password" 
                                        />
                                        <ErrorMessage
                                            component="div"
                                            name="rePassword"
                                            className="invalid-feedback"
                                        />
                                        <span className="eyeIconWrapper">
                                            {isRePasswordVisible?(
                                                <i className="far fa-eye" onClick={()=>setIsRePasswordVisible(!isRePasswordVisible)}></i>
                                            ):(
                                                <i className="far fa-eye-slash" onClick={()=>setIsRePasswordVisible(!isRePasswordVisible)} ></i>
                                            )}
                                        </span>
                                    </div>
                                </div>
                                <div className="my-4 text-center">
                                    <button type="submit" className="btn btn-default btn-block py-2 rounded-0 bg-FF6400 text-light" disabled={isLoading ?(true):("")}>  {isLoading&& <CircularProgress size={18} color="inherit" className="mr-2" />} Submit</button>
                                    <Link  to="/profileInfo"><p className="outline-button cursor-pointer mt-3">Back</p></Link>
                                </div> 
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </>
    )
}
