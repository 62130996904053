import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {useNavigate } from 'react-router-dom';

// components
import Footer from '../../Components/Footer/Footer';
import Navbar from '../../Components/Navbar/Navbar';

export default function Home() {
    const navigate = useNavigate();
    const userAuth = useSelector((state) => state.user.authStatus)

    useEffect(() => {
        if(!userAuth){
            navigate("/")
        }
    }, [navigate, userAuth])

    return (
        <>
            <Navbar/>
                <div className="my_home container text-center py-5 my-lg-5">
                    <h1>Website is Under Contstruction</h1>
                    <img src="Assets/Frame.png" className="img-fluid"  alt="frame_img"/>
                </div>
            <Footer/>
        </>
    )
}
