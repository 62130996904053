import React, {useState} from 'react'
import {
    CardElement,
    useStripe,
    useElements,
  } from '@stripe/react-stripe-js';

import Snackbar from '@mui/material/Snackbar';
import { completeSubscription, doSubscribe } from '../../ApiConnections/Stripe/Stripe';
import CircularProgress from '@mui/material/CircularProgress';
import {useNavigate } from 'react-router-dom';

import { useSelector } from 'react-redux'
import { getProfileDetails } from '../../ApiConnections/Profile/Profile';
// import { SET_USER_DETAILS } from '../../Reducers/types';

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#F79A5E",
      color: "#F79A5E",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#F79A5E" },
      "::placeholder": { color: "#F79A5E" }
    },
    invalid: {
      iconColor: "#ff000c",
      color: "#ff000c"
    }
  }
}


export default function CheckoutForm(props) {
  const { planId } = props;

  const navigate = useNavigate();

  const stripe = useStripe();
  const elements = useElements();

  const userDetails = useSelector((state) => state.user.userDetails)

  const [isSnackBar, setIsSnackBar] = useState(false)
  const [SnackMessage, setSnackMessage] = useState("Oops! Something went wrong please check! Again.")
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (event) => {
    setIsLoading(true)
    event.preventDefault();
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (!error) {
      doSubscribe(paymentMethod, planId, userDetails.token, doSubscribeCallback)
      //send token to backend here
    } else {
      setIsLoading(false)
      setIsSnackBar(true)
      setSnackMessage(error.message)
    }
  };


  const doSubscribeCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        setIsSnackBar(true)
        setSnackMessage(response.data.message);
        getProfileDetails(userDetails.token, getProfileCallback)
        navigate("/billing")
      }
      else if (response.data.status === false && response.data.error_code === "ERR031") {
        setIsLoading(true)
        confirmPaymentMethod(response.data.data)
      } else if (response.data.status === false) {
        if (response.data.error_msg) {
          setIsSnackBar(true)
          setSnackMessage(response.data.error_msg)
        } else {

        }
      } else {

      }
    }
    setIsLoading(false)
  }

    const confirmPaymentMethod=(data)=>{
      stripe
      .confirmCardPayment(data.client_secret)
      .then(function(result) {
        
        if(result.error){
          setIsSnackBar(true);
          setSnackMessage(result.error.message)
        }else if(result.paymentIntent && result.paymentIntent.status=== "succeeded"){
            completeSubscription(data.subscription_id, data.plan_id, userDetails.token, doSubscribeCallback)
        }
       
        
      }).catch((err) => {
        
      });
    }

    const getProfileCallback=(response)=>{
      if(response.status === 200){
        // dispatch({ type: SET_USER_DETAILS, payload: { userDetails: response.data.data.user } })
        localStorage.setItem('MacroCodexAuthToken', response.data.data.user.token);
      }
    }
    
    const closeSnack=()=>{
      setIsSnackBar(false)
    }

    return (
      <>
        <div className="container mt-3">
            <div className="row">
                <div className="col-lg-6">
                    <form onSubmit={handleSubmit} width="180">
                        <CardElement  options={CARD_OPTIONS}  />
                        <button className="btn btn-default bg-FF6400 px-5 py-2 rounded-0 text-light font-weight-bold mt35" disabled={isLoading ?(true):("")} > {isLoading&& <CircularProgress size={18} color="inherit" className="mr-2" />} Pay</button>
                    </form>
                </div>
            </div>
        </div>
        <Snackbar
          open={isSnackBar}
          onClose={(e)=>closeSnack(e)}
          anchorOrigin={{vertical: 'bottom', horizontal: 'center',}}
          message= {SnackMessage}
          autoHideDuration={4000}
        />
      </>
    )
}
