import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { unsetProfileSuccess, updateFullName } from '../../redux/action/userActions';

export default function EditProfileDetails() {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const profileSuccess = useSelector((state) => state.user.profileSuccess)
    const userDetails = useSelector((state) => state.user.userDetails)
    const [fullName, setFullName] = useState(userDetails.full_name)
    const actionChangeFullName = () => {
        dispatch(updateFullName(fullName))
    }

    useEffect(() => {
      if(profileSuccess) {
        navigate("/profileInfo")
        dispatch(unsetProfileSuccess())
      }
    }, [profileSuccess, dispatch, navigate])
    
    return (
        <>
            <div className="d-flex justify-content-end">
                <Link to="/profileInfo">
                    <button className="outline-button mr-3" >Cancel</button>
                </Link>
                <button className="outline-button bg-FF6400 text-light" onClick={() => actionChangeFullName()}>Save Changes</button>
            </div>
            <div className="row mt-4 align-items-center">
                <div className="col-lg-4 ml-3">
                    <div className="form-group">
                        <label htmlFor="Full Name">Full Name</label>
                        <input type="text" className="form-control" defaultValue={userDetails.full_name} onChange={(e) => setFullName(e.target.value)} placeholder="Enter your full name" />
                        <div className="invalid-feedback">
                            <i className="fas fa-info-circle"></i>
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="Email">Email</label>
                        <input type="text" className={"form-control"} defaultValue={userDetails.email} placeholder="Enter your Email" disabled={true} />
                    </div>
                </div>
            </div>
        </>
    )
}
