export const CREATE_USER_ACCOUNT = 'CREATE_USER_ACCOUNT';
export const SET_SINGUP_SUCCESS = 'SET_SINGUP_SUCCESS';
export const USER_AUTH = 'USER_AUTH';
export const SET_USER = 'SET_USER';
export const UNSET_USER = 'UNSET_USER';
export const GET_USER = 'GET_USER';
export const UPDATE_FULL_NAME = 'UPDATE_FULL_NAME';
export const SET_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UNSET_PROFILE_SUCCESS = 'UNSET_PROFILE_SUCCESS'
export const SET_AUTH_ERROR = "SET_AUTH_ERROR";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD"
export const CHANGE_PASSWORD_NO_AUTH = "CHANGE_PASSWORD_NO_AUTH"
export const REQUEST_OTP = "REQUEST_OTP"
export const VERIFY_OTP = "VERIFY_OTP";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS"
export const OTP_SENT_SUCCESS = "OTP_SENT_SUCCESS"
export const UNSET_OTP_SENT_SUCCESS = "UNSET_OTP_SENT_SUCCESS"
export const SET_PASSWORD = "SET_PASSWORD"
export const UNSET_PASSWORD = "UNSET_PASSWORD"
export const SET_EMAIL_FOR_OTP = "SET_EMAIL_FOR_OTP"



export const createUserAccount = (userData) =>{
    return {
        type: CREATE_USER_ACCOUNT,
        userData
    }
}

export const userAuth = (authData) => {
    return {
        type: USER_AUTH,
        authData
    };
};

export const unsetUser = (userData) => {
    return {
        type: UNSET_USER,
        userData
    };
};

export const setUser = (user) => {
    return {
        type: SET_USER,
        user
    };
};

export const getUser = () => {
    return {
        type: GET_USER
    };
};

export const updateFullName = (userData) => {
    return {
        type: UPDATE_FULL_NAME,
        userData
    }
}

export const setAuthError = (error) => {
    return {
        type: SET_AUTH_ERROR,
        error
    }
}

export const setProfileSuccess = (payload) => {
    return {
        type: SET_PROFILE_SUCCESS,
        payload
    }
}

export const unsetProfileSuccess = (payload) => {
    return{
        type : UNSET_PROFILE_SUCCESS,
        payload
    }
}

export const changePassword = (userData) => {
    return {
        type: CHANGE_PASSWORD,
        userData
    }
}

export const changePasswordWithoutAuth = (userData) => {
    return {
        type: CHANGE_PASSWORD_NO_AUTH,
        userData
    }
}

export const setSignupSuccess = (payload) => {
    return {
        type: SET_SINGUP_SUCCESS,
        payload
    }
}

export const requestOTP = (payload) => {
    return {
        type: REQUEST_OTP,
        payload
    }
}

export const verifyOTP = (userData) => {
    return {
        type: VERIFY_OTP,
        userData
    }
}

export const verifyOTPSuccess = (payload) => {
    return {
        type: VERIFY_OTP_SUCCESS,
        payload
    }
}

export const otpSentSuccess = (payload) => {
    return {
        type: OTP_SENT_SUCCESS,
        payload
    }
}

export const unsetOtpSentSuccess = () => {
    return {
        type: UNSET_OTP_SENT_SUCCESS
    }
}


export const setPassword = (payload) => {
    return {
        type: SET_PASSWORD,
        payload
    }
}

export const unsetPassword = () => {
    return {
        type: UNSET_PASSWORD
    }
}


export const forgotPassword = (userData) => {
    return {
        type: FORGOT_PASSWORD,
        userData
    };
};

export const forgetPasswordSucess = (payload) => {
    return {
        type: FORGOT_PASSWORD_SUCCESS,
        payload
    }
}

export const setEmailForOtp = (payload) => {
    return {
        type: SET_EMAIL_FOR_OTP,
        payload
    }
}