import React from 'react'
import ChangePasswordForm from '../../Components/ChangePasswordForm/ChangePasswordForm'
import Footer from '../../Components/Footer/Footer'
import Navbar from '../../Components/Navbar/Navbar'

export default function ChangeAccountPassword() {
  
  
    return (
      <>
        <Navbar />
            <div
                className="my_profile_personal_info container container-body container-padding my-5"
                style={{ height: 489 }}
            >
                <div class="row mt-4 align-items-center">
                    <div class="col-lg-4 ml-3">
                        <ChangePasswordForm />
                    </div>
                </div>
            </div>
        <Footer/>
      </>
    );
}
