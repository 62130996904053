import React, { useState, useEffect, useMemo, useRef } from 'react'
import Select from 'react-select';
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom';
// import css
import './Highchart.css'
// components
import HighchartsReact from 'highcharts-react-official';
import indicatorsAll from "highcharts/indicators/indicators-all";
import Highcharts from 'highcharts/highstock';

const Highchart = (props) => {
    const location = useLocation()
    
    const highchart_data_reducer = useSelector((state) => state.highchart.highchartData)
    
    indicatorsAll(Highcharts);
    const [dataOhlc, setDataOhlc] = useState([])
    const [dataVolume, setDataVolume] = useState([])
    const [fileName, setFileName] = useState("")
    const [chartType, setChartType] = useState('')
    const chartRef = useRef(null)

    useEffect(() => {
        setFileName("Values")
        setDataVolume(highchart_data_reducer?.payload?.vol)
        if(props.selectedStrategy === "volatility") {
            setDataOhlc(highchart_data_reducer?.payload?.ohlc ?? [])
            setChartType('ohlc')
        } else {
            setDataOhlc(highchart_data_reducer?.payload?.close ?? [])
            setChartType('line')
        }
    }, [highchart_data_reducer, props.selectedStrategy])


    useEffect(() => {
        setDataOhlc([])
        setDataVolume([])
    }, [location])


    // const overlayOptions = [
    //     { value: "abands", label: "Acceleration Bands" },
    //     { value: "bb", label: "Bollinger Bands" },
    //     { value: "dema", label: "DEMA (Double Exponential Moving Average)" },
    //     { value: "ema", label: "EMA (Exponential Moving Average)" },
    //     { value: "ikh", label: "Ichimoku Kinko Hyo" },
    //     { value: "keltnerchannels", label: "Keltner Channels" },
    //     { value: "linearRegression", label: "Linear Regression" },
    //     { value: "pivotpoints", label: "Pivot Points" },
    //     { value: "pc", label: "Price Channel" },
    //     { value: "priceenvelopes", label: "Price Envelopes" },
    //     { value: "psar", label: "PSAR (Parabolic SAR)" },
    //     { value: "sma", label: "SMA (Simple Moving Average)" },
    //     { value: "supertrend", label: "Super Trend" },
    //     { value: "tema", label: "TEMA (Triple Exponential Moving Average)" },
    //     { value: "vbp", label: "VbP (Volume by Price)" },
    //     { value: "wma", label: "WMA (Weighted Moving Average)" },
    //     { value: "vwap", label: "VWAP (Volume Weighted Average Price)" },
    //     { value: "zigzag", label: "Zig Zag" },
    // ]

    const oscillatorOptions = [
        { value: "apo", label: "Absolute price indicator" },
        { value: "ad", label: "A/D (Accumulation/Distribution)" },
        { value: "aroon", label: "Aroon" },
        { value: "aroonoscillator", label: "Aroon oscillator" },
        { value: "atr", label: "ATR (Average True Range)" },
        { value: "ao", label: "Awesome oscillator" },
        { value: "cci", label: "CCI (Commodity Channel Index)" },
        { value: "chaikin", label: "Chaikin" },
        { value: "cmf", label: "CMF (Chaikin Money Flow)" },
        { value: "disparityindex", label: "Disparity Index" },
        { value: "cmo", label: "CMO (Chande Momentum Oscillator)" },
        { value: "dmi", label: "DMI (Directional Movement Index)" },
        { value: "dpo", label: "Detrended price" },
        { value: "linearRegressionAngle", label: "Linear Regression Angle" },
        { value: "linearRegressionIntercept", label: "Linear Regression Intercept" },
        { value: "linearRegressionSlope", label: "Linear Regression Slope" },
        { value: "keltnerchannels", label: "Klinger Oscillator" },
        { value: "macd", label: "MACD (Moving Average Convergence Divergence)" },
        { value: "mfi", label: "MFI (Money Flow Index)" },
        { value: "momentum", label: "Momentum" },
        { value: "natr", label: "NATR (Normalized Average True Range)" },
        { value: "obv", label: "OBV (On-Balance Volume)" },
        { value: "ppo", label: "Percentage Price oscillator" },
        { value: "roc", label: "RoC (Rate of Change)" },
        { value: "rsi", label: "RSI (Relative Strength Index)" },
        { value: "slowstochastic", label: "Slow Stochastic" },
        { value: "stochastic", label: "Stochastic" },
        { value: "trix", label: "TRIX" },
        { value: "williamsr", label: "Williams %R" }
    ]

    const brandDark = useMemo(() =>
    ({
        chart: {
            height: 560,
        },
        yAxis: [{
            height: '60%'
        },
        {
            top: '60%',
            height: '20%'
        },
        {
            top: '80%',
            height: '20%',
        }],
        plotOptions: {
            series: {
                showInLegend: true,
                animation: false,
            }
        },
        series: [{
            type: chartType,
            id: 'aapl',
            name: fileName,
            color: 'white',
            data: dataOhlc,
            fontWeight: 'lighter'
        },
        {
            type: 'column',
            id: 'aapl-volume',
            name: 'Volume',
            data: dataVolume,
            color: '#FF6400',
            yAxis: 2,
        },
        {
            id: 'overlay',
            linkedTo: 'aapl',
            name: 'overlay',
            key: "overlay",
            yAxis: 0,
            showInLegend: true,
            params: {
                volumeSeriesID: 'aapl-volume',
            },
        },
        {
            type: "macd",
            id: 'oscillator',
            linkedTo: 'aapl',
            yAxis: 1,
            key: 'oscillator',
            color: '#00FFFF',
            showInLegend: true,
            params: {
                volumeSeriesID: 'aapl-volume',
            },

        }],
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 200
                },
                chartOptions: {
                    rangeSelector: {
                        inputEnabled: false
                    }
                }
            }]
        }
    }), [dataOhlc, dataVolume, fileName, chartType])


    // useEffect(() => {
    //   let container = chartRef.current.container.current;
    //   container.style.height = "100%";
    //   container.style.width = "100%";
    //   chartRef.current.chart.reflow();
    // }, [chartRef])
    

    // const responseHighchart = (mydata) => {

    //     console.log(mydata)

    // }

    // const onChangeHandlerOverlay = (e) => {
    //     console.log("currently applied indicator is :", e.value)
    //     console.log("previously applied indicator was: ", chartRef.current.chart.series[2].type)
    //     if(chartRef.current.chart.series[2].type!==e.value)
    //     { 
    //         chartRef.current.chart.series[2].update({
    //             type: e.value,
    //             animation: {
    //                 duration: 1000,
    //                 easing: 'easeOutBounce',
    //                 key:Math.random()
    //             },
    //         });
    //         console.log("The current value of chart series is inside the if statement: ", chartRef.current.chart.series[2].type )
    //     }
    // }

    const onChangeHandlerOsc = (e, name) => {
        if (chartRef.current.chart.series[3].type !== e.value) {
            chartRef.current.chart.series[3].update({
                type: e.value,
                animation: {
                    duration: 1000,
                    easing: 'easeOutBounce',
                    key: Math.random()
                },
            });
        }
    }

    // for select input
    const customStyles = {
        menuList: (styles, state) => {
            return {
                ...styles,
                maxHeight: 180,
            };
        },
        option: (provided, state) => {
            return {
                ...provided,
                "&:hover": {
                    color : "black",
                    backgroundColor: "#E8E8E8"
                },
                backgroundColor: state.isSelected && "#352E57" 
            };
        },
        control: (base, state) => ({
            ...base,
            '&:hover': { borderColor: '#352E57' }, // border style on hover
            border: '1px solid lightgray', // default border color
            boxShadow: 'none', // no box-shadow
        }),
    };

    return (
        <>
            <div className="highchart_section w-100">
                <div className="row overlay-oscillator">
                    <div className="col-lg-6 col-sm-12 my-2">
                        {/* <div>
                            <h6 htmlFor="overlays">Overlays:</h6>
                            <div>
                                <Select
                                    name="overlay"
                                    defaultValue="pc"
                                    onChange={(e) => onChangeHandlerOverlay(e)}
                                    options={overlayOptions}
                                    placeholder="Select an Overlay"
                                />
                            </div>
                        </div> */}
                    </div>
                    <div className="col-lg-6 col-sm-12 my-2">
                        <div>
                            <h6 htmlFor="oscillators">Oscillators:</h6>
                            <div>
                                <Select
                                    name="oscillator"
                                    defautlValue="macd"
                                    onChange={(e) => onChangeHandlerOsc(e)}
                                    options={oscillatorOptions}
                                    placeholder="Select an Oscillator"
                                    styles={customStyles}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {dataOhlc?.length !== 0 &&
                    <div className="brand_dark d-flex justify-content-end">
                        <HighchartsReact
                            ref={chartRef}
                            highcharts={Highcharts}
                            constructorType={'stockChart'}
                            containerProps={{style : {width : "100%", paddingRight : "8px"}}}
                            options={brandDark}
                        />
                    </div>
                }
                {dataOhlc?.length === 0 && <div className="brand_dark_content px-3 text-center">
                    <h1>Your chart will be displayed here</h1>
                </div>}
            </div>
        </>
    )
} 

export default Highchart
