import api from '../../services/api';



export const getSubscriptionDetails=(token, callback) => {
    api
    .get('user/subscription-detail',{
        headers: { 'Authorization': `Bearer ${token}` } 
    })
    .then((response) => callback(response))
    .catch(error => {console.log("Oops! Something went wrong ", error)});
}


export const getManageSubscriptionLink=(token, callback)=>{
    api
    .get('user/my-portal',{
    })
    .then((response) => callback(response))
    .catch(error => {console.log("Oops! Something went wrong ", error)});
}



export const doSubscribe=(payload, plan_id, token, callback)=>{
    api
    .post('user/subscribe', {
        payload,
        plan_id
    },
    )
    .then((response) => callback(response))
    .catch(error => {console.log("Oops! Something went wrong ", error)});
}



export const completeSubscription=(subscription_id, plan_id, token, callback)=>{
    api
    .post('user/complete-3D-subscription',{
        subscription_id,
        plan_id
    },
    )
    .then((response) => callback(response))
    .catch(error => {console.log("Oops! Something went wrong ", error)});
}


export const getSelectPlanById=(planId, callback)=>{
    api
    .get('plan/selected-plan?plan_id='+planId,{
       
    })
    .then((response) => callback(response))
    .catch(error => {console.log("Oops! Something went wrong ", error)});
}