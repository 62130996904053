import axios from 'axios';

//Axios Create a client
export const api = axios.create({
  baseURL: 'https://react.macrocodex.com/v1/api/',
  withCredentials: true,
  'Access-Control-Allow-Origin': '*',
  headers: {
    Authorization: `Bearer ${localStorage.getItem("MacroCodexAuthToken")}`
  }

});

// for handling invalid token exception
api.interceptors.response.use(
  (response) => {
      return response;
  }, 
  async (err) => {
      if(err.response.status === 401) {
        localStorage.removeItem('MacroCodexAuthToken');
        alert("Token expired, you need to login again...!")
        document.location.href = "/"
      }
  })
export default api;
