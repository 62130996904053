import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

export default function ProfileInfo() {
  
    const userDetails = useSelector((state) => state.user.userDetails)

    return (
        <>
            <div className="d-flex justify-content-end">
                <Link className="outline-button mr-3" to="change-password">
                    Change Password
                </Link>
                <Link className="outline-button" to="edit-profile">
                    Edit Profile
                </Link>
            </div>
            <h5>Personal Information</h5>
            <div className="row mt-4 align-items-center">
                <div className="col-lg-1 col-3 mb-3 text-center">
                    <img src="Assets/defaultProfile.png" width="102" alt="" />
                </div>
                <div className="col-lg-4 ml-3">
                    <p className="font-weight-bold mb-0">{userDetails.full_name}</p>
                    <p>{userDetails.email}</p>
                </div>
            </div>
        </>
    )
}
