import React,{useState, useEffect} from 'react'
import { useDispatch, useSelector} from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

//redux action
import { changePasswordWithoutAuth, forgetPasswordSucess, setEmailForOtp } from '../../redux/action/userActions';
import { setButtonLoader } from '../../redux/action/loaderAction';

// COMPONENTS
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';

export default function ResetForm() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isRePasswordVisible, setIsRePasswordVisible] = useState(false)

    const[errorCode, setErrorCode] = useState("");
    const[errorMessage, setErrorMessage] = useState("");
    
    const isLoading = useSelector((state)=>state.loader.buttonLoader)
    const isResetPasswordSucess = useSelector((state)=> state.user.forgetPasswordSucess)
    const otpEmail = useSelector((state)=>state.user?.otpEmail)
    const authErrorRedux = useSelector((state) => state.user.error)

    useEffect(() => {
        if(isResetPasswordSucess === true){
            dispatch(forgetPasswordSucess(false))
            dispatch(setEmailForOtp(null))
            navigate("/auth")
        }
    }, [isResetPasswordSucess, navigate, dispatch])

    useEffect(() => {
        if(authErrorRedux !== null){
            setErrorCode(authErrorRedux?.error_code)
            setErrorMessage(authErrorRedux?.error_msg)  
        }
    }, [authErrorRedux])
   
    const resetFormSchema = Yup.object().shape({
        password: Yup.string()
            .min(8, "Password must be 8 characters at minimum")
            .required("Password is required"),         
        cPassword: Yup.string()
            .required('Confirm Password is required')
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
    });

    return (
        <>
            <Formik
                initialValues={{ 
                    password: "",
                    cPassword:""
                }}
                validationSchema={resetFormSchema}
                onSubmit={(values) => {   
                    const changePasswordData = {
                        password : values.password,
                        c_password : values.cPassword,
                        email : otpEmail,
                    }
                    dispatch(changePasswordWithoutAuth(changePasswordData))
                    dispatch(forgetPasswordSucess(false))
                    dispatch(setButtonLoader(true));
                }}
            >
                {(props) => (
                    <Form>
                        <h3 className="mb-4">Reset Password </h3>
                        <div className="form-group">
                            <label htmlFor="Password">Password</label>
                            <div className="positionRelative">
                                <Field
                                    type={isPasswordVisible ? ("text") : ("password")}
                                    name="password"
                                    className={`form-control ${props.touched.password && props.errors.password ? "is-invalid" : ""}`}
                                    placeholder="Enter 8 Digit Password"
                                />
                                {errorCode === "password" ? (
                                    <div className="invalid-feedback">
                                        <i className="fas fa-info-circle"></i> {errorMessage}
                                    </div>
                                ) : (
                                    <ErrorMessage
                                        component="div"
                                        name="password"
                                        className="invalid-feedback"
                                    />
                                )}
                                <span className="eyeIconWrapper">
                                    {isPasswordVisible ? (
                                        <i className="far fa-eye" onClick={() => setIsPasswordVisible(!isPasswordVisible)}></i>
                                    ) : (
                                        <i className="far fa-eye-slash" onClick={() => setIsPasswordVisible(!isPasswordVisible)} ></i>
                                    )}
                                </span>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="Password">Re-enter Password</label>
                            <div className="positionRelative">
                                <Field
                                    type={isRePasswordVisible ? ("text") : ("password")}
                                    name="cPassword"
                                    className={`form-control ${props.touched.cPassword && props.errors.cPassword ? "is-invalid" : ""} ${errorCode === 'c_password' ? "is-invalid" : ""} `}
                                    placeholder="Re-enter 8 Digit Password"

                                />
                                {errorCode === "c_password" ? (
                                    <div className="invalid-feedback">
                                        <i className="fas fa-info-circle"></i> {errorMessage}
                                    </div>
                                ) : (
                                    <ErrorMessage
                                        component="div"
                                        name="cPassword"
                                        className="invalid-feedback"
                                    />
                                )}
                                <span className="eyeIconWrapper">
                                    {isRePasswordVisible ? (
                                        <i className="far fa-eye" onClick={() => setIsRePasswordVisible(!isRePasswordVisible)}></i>
                                    ) : (
                                        <i className="far fa-eye-slash" onClick={() => setIsRePasswordVisible(!isRePasswordVisible)} ></i>
                                    )}
                                </span>
                            </div>
                        </div>
                        <div className="my-4 text-center">
                            <button type="submit" className="btn btn-default btn-block py-2 rounded-0 bg-FF6400 text-light" disabled={isLoading ? (true) : ("")} >  {isLoading && <CircularProgress size={18} color="inherit" className="mr-2" />} Reset Password</button>
                        </div>
                    </Form>
                )}
            </Formik> 
        </>
    )
}
