import {FORGOT_PASSWORD_SUCCESS, OTP_SENT_SUCCESS, SET_AUTH_ERROR, SET_EMAIL_FOR_OTP, SET_PASSWORD, SET_PROFILE_SUCCESS, SET_SINGUP_SUCCESS, SET_USER, UNSET_OTP_SENT_SUCCESS, UNSET_PASSWORD, UNSET_PROFILE_SUCCESS, UNSET_USER, VERIFY_OTP_SUCCESS} from '../action/userActions'

const initialState = {
    authStatus : false,
    userDetails:{},
    error : null,
    profileSuccess : false,
    signupSuccess : false,
    verifyOTPSuccess : false,
    otpSentSuccess : false,
    forgetPasswordSucess : false,
    password: null,
    otpEmail : null
}


export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case UNSET_USER:
            return {
                initialState
            };
        case SET_USER:
            return {
                ...state,
                authStatus : true,
                userDetails : action.userData
            };
        case SET_AUTH_ERROR:
            return {
                ...state,
                error : action.errorData
            };
        case SET_PROFILE_SUCCESS:
            return {
                ...state,
                profileSuccess: action.payload
            };
        case SET_SINGUP_SUCCESS:
            return {
                ...state,
                signupSuccess: action.payload
            };
        case VERIFY_OTP_SUCCESS:
            return {
                ...state,
                verifyOTPSuccess: action.payload
            };
        case FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                forgetPasswordSucess: action.payload
            }
        case OTP_SENT_SUCCESS:
            return {
                ...state,
                otpSentSuccess: action.payload
            }
        case UNSET_OTP_SENT_SUCCESS:
            return {
                ...state,
                otpSentSuccess: false
            }
        case SET_PASSWORD:
            return {
                ...state,
                password: action.payload
            }
        case UNSET_PASSWORD:
            return {
                ...state,
                password: null
            }  
        case UNSET_PROFILE_SUCCESS:
            return {
                ...state,
                profileSuccess: false
            }      
        case SET_EMAIL_FOR_OTP:
            return {
                ...state,
                otpEmail: action.payload
            }      
                    
        default:
            return state;
    }
}