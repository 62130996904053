import React from 'react'
import {Link } from 'react-router-dom';

export default function BillingDetails() {
    return (
        <div className="container my-5">
            <h3>Billing Details</h3>
            <h3 className="my-4">You have not subscribed to any plan yet.</h3>
            <Link className="text-light" to="/subscription/2">
                <button className="btn btn-default px-5 py-2 mt-4 rounded-0 bg-FF6400 text-light">
                    Check Plans
                </button>
            </Link> 
        </div>
    )
}
  