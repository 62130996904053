import React,{useState, useEffect} from 'react'
import CircularProgress from '@mui/material/CircularProgress';

//redux
import {useDispatch, useSelector} from 'react-redux'
import { setButtonLoader } from '../../redux/action/loaderAction';
import { changePassword, SET_PROFILE_SUCCESS, unsetPassword, unsetProfileSuccess } from '../../redux/action/userActions';
import { Link, useNavigate } from 'react-router-dom';

export default function ChangePasswordOTP(props) {
    const navigate = useNavigate();
 
    const dispatch = useDispatch()

    const[input1, setInput1] = useState("");
    const[input2, setInput2] = useState("");
    const[input3, setInput3] = useState("");
    const[input4, setInput4] = useState("");
    const[input5, setInput5] = useState("");
    const[input6, setInput6] = useState("");

    const profileSuccess = useSelector((state) => state.user.profileSuccess)
    const userPassword = useSelector((state) => state.user.password)
    const isLoading = useSelector((state)=>state.loader.buttonLoader)

    const[Timerseconds, setTimerseconds] = useState("60");
    useEffect(() => {
        if (Timerseconds > 0) {
            let currentTimer = Timerseconds
            setTimeout(() => setTimerseconds(currentTimer-1), 1000);
          } else {
            setTimerseconds("0")
          }
    }, [Timerseconds])

    useEffect(() => {
        if(profileSuccess === true){
            dispatch(unsetProfileSuccess())
            navigate("/profileInfo")
        }
    }, [profileSuccess, dispatch, navigate])


    const onBackKeyPress=(e)=>{        
        if (e.keyCode === 8 || e.keyCode === 37) {
            e.target.previousSibling.focus();
        } else {
            e.target.nextSibling.focus();
        }
    }

    const action4ChangePasswordOTP=()=>{
        dispatch(setButtonLoader(true))
        const changePasswordData = {
            password: userPassword,
            otp : input1+input2+input3+input4+input5+input6
        }
        dispatch({type: SET_PROFILE_SUCCESS, payload: false})
        dispatch(changePassword(changePasswordData))
        dispatch(unsetPassword())
    }
  
    return (
        <>
            <div className="row mt-4 align-items-center">
                <div className="col-lg-4 ml-3">
                    <h3 className="mb-4">Change Password </h3>

                    <p className="txt-4F4F4F fontSize14">
                        Please enter 6 digit OTP sent on your registered Email
                    </p>
                    <div className="form-group">
                        <div className="d-flex mt-4">
                            <input type="tel" 
                                className={"form-control mx-2 text-center"} 
                                maxLength="1" 
                                onChange={(e)=> {setInput1(e.target.value)}}
                                onKeyUp={(e)=>onBackKeyPress(e)}
                            />
                            <input type="tel" 
                                className={"form-control mx-2 text-center"} 
                                maxLength="1" 
                                onChange={(e)=> {setInput2(e.target.value);}}
                                onKeyUp={(e)=>onBackKeyPress(e)}
                            />
                            <input type="tel" 
                                className={"form-control mx-2 text-center"} 
                                maxLength="1" 
                                onChange={(e)=> {setInput3(e.target.value)}}
                                onKeyUp={(e)=>onBackKeyPress(e)}
                            />
                            <input type="tel" 
                                className={"form-control mx-2 text-center"} 
                                maxLength="1" 
                                onChange={(e)=> {setInput4(e.target.value)}}
                                onKeyUp={(e)=>onBackKeyPress(e)}
                            />
                            <input type="tel" 
                                className={"form-control mx-2 text-center"} 
                                maxLength="1" 
                                onChange={(e)=> {setInput5(e.target.value)}}
                                onKeyUp={(e)=>onBackKeyPress(e)}
                            />
                            <input type="tel" 
                                className={"form-control mx-2 text-center"} 
                                maxLength="1" 
                                onChange={(e)=> {setInput6(e.target.value)}}
                                onKeyUp={(e)=>onBackKeyPress(e)}
                            />
                        </div>                
                    </div>
                    <div className="form-group d-flex justify-content-between mx-2">
                        <p className="txt-316fe8 cursor-pointer">Resend</p>
                        <p>{Timerseconds} Sec</p>
                    </div>
                    <div className="my-3 text-center">
                        <button type="button" className="btn btn-default btn-block py-2 rounded-0 bg-FF6400 text-light d-flex align-items-center justify-content-center" onClick={()=>action4ChangePasswordOTP()} disabled={isLoading ?(true):("")} > {isLoading&& <CircularProgress size={18} color="inherit" className="mr-2" />} Submit</button>
                        <Link  to="/profileInfo/change-password">
                            <p className="outline-button cursor-pointer mt-3">
                                Back
                            </p>
                        </Link>
                    </div> 
                </div>
            </div>
        </>
    )
}
