import React,{useState, useEffect} from 'react'
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

//redux
import { useDispatch, useSelector } from 'react-redux';
import { createUserAccount } from '../../redux/action/userActions';
import { setButtonLoader } from '../../redux/action/loaderAction';
import { setSignupSuccess } from '../../redux/action/userActions';

//components
import CircularProgress from '@mui/material/CircularProgress';
import { Link, useNavigate } from 'react-router-dom';

export default function SignUpForm(props) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
   
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isRePasswordVisible, setIsRePasswordVisible] = useState(false)
    
    const[errorCode, setErrorCode] = useState("");
    const[errorMessage, setErrorMessage] = useState("");
    
    const signupSuccess = useSelector((state) => state.user.signupSuccess)
   
    const isLoading = useSelector((state)=>state.loader.buttonLoader)
    const authErrorRedux = useSelector((state) => state.user.error)

    useEffect(() => {
        if (signupSuccess === true) {
            navigate("/auth/verify-email/signup")
            dispatch(setSignupSuccess(false))
        }
    }, [signupSuccess, dispatch, navigate])

    useEffect(() => {
        if(authErrorRedux !== null){
          setErrorCode(authErrorRedux?.error_code)
          setErrorMessage(authErrorRedux?.error_msg)  
        }
    }, [authErrorRedux])
    
    const CreateUserSchema = Yup.object().shape({
        fullName: Yup.string()
            .min(2, "Invalid name")
            .required("Full Name is required"),
        email: Yup.string()
            .email("Invalid email address format")
            .required("Email is required"),
        password: Yup.string()
            .min(8, "Password must be 8 characters at minimum")
            .required("Password is required"),        
        cPassword: Yup.string()
            .required('Confirm Password is required')
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
    });

    return (
        <>
            <h3 className="mb-4">Sign Up</h3>
            <Formik
                initialValues={{ 
                    fullName: "",
                    email: "", 
                    password: "",
                    cPassword:""
                }}
                validationSchema={CreateUserSchema}
                onSubmit={(values) => {
                    dispatch(createUserAccount(values))
                    dispatch(setButtonLoader(true));
                    dispatch(setSignupSuccess(false))
                }}
            >
                {
                    (props) => (
                        <Form>
                            <div className="form-group">
                                <label htmlFor="Full Name">Full Name</label>
                                <Field type="text" 
                                    name="fullName"
                                    className={`form-control ${props.touched.fullName && props.errors.fullName ? "is-invalid" : ""}`}  
                                    placeholder="Enter your full name"
                                />
                                <ErrorMessage
                                    component="div"
                                    name="fullName"
                                    className="invalid-feedback"
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="Email">Email</label>
                                <Field type="email"
                                    name="email" 
                                    className={`form-control ${props.touched.email && props.errors.email ? "is-invalid" : ""} ${errorCode === 'email' ? "is-invalid": ""} `} 
                                    placeholder="Enter your Email"
                                />
                                {errorCode === "email"?(
                                    <div className="invalid-feedback">
                                        <i className="fas fa-info-circle"></i> {errorMessage}
                                    </div>
                                ) : (
                                    <ErrorMessage
                                        component="div"
                                        name="email"
                                        className="invalid-feedback"
                                    />
                                )}
                            </div>
                            <div className="form-group">
                                <label htmlFor="Password">Password</label>
                                <div className="positionRelative">
                                    <Field type={isPasswordVisible?("text"):("password")} 
                                        name="password"
                                        className={`form-control ${props.touched.password && props.errors.password ? "is-invalid" : ""}`} 
                                        placeholder="Enter Password"
                                    />
                                    <ErrorMessage
                                        component="div"
                                        name="password"
                                        className="invalid-feedback"
                                    />
                                    <span className="eyeIconWrapper">
                                        {isPasswordVisible?(
                                            <i className="far fa-eye" onClick={()=>setIsPasswordVisible(!isPasswordVisible)}></i>
                                        ) : (
                                            <i className="far fa-eye-slash" onClick={()=>setIsPasswordVisible(!isPasswordVisible)} ></i>
                                        )}
                                    </span>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="Password">Re-enter Password</label>
                                <div className="positionRelative">
                                    <Field type={isRePasswordVisible?("text"):("password")}
                                        name="cPassword"
                                        className={`form-control ${props.touched.cPassword && props.errors.cPassword ? "is-invalid" : ""} ${errorCode === 'c_password' ? "is-invalid": ""} `}
                                        placeholder="Re-enter Password"
                                    />
                                    {errorCode === "c_password"?(
                                        <div className="invalid-feedback">
                                            <i className="fas fa-info-circle"></i> {errorMessage}
                                        </div>
                                    ) : (
                                        <ErrorMessage
                                            component="div"
                                            name="cPassword"
                                            className="invalid-feedback"
                                        />
                                    )}
                                    <span className="eyeIconWrapper">
                                        {isRePasswordVisible?(
                                            <i className="far fa-eye" onClick={()=>setIsRePasswordVisible(!isRePasswordVisible)}></i>
                                        ):(
                                            <i className="far fa-eye-slash" onClick={()=>setIsRePasswordVisible(!isRePasswordVisible)} ></i>
                                        )}
                                    </span>
                                </div>
                            </div>
                            <div className="my-3 text-center">
                                <button type="submit" className="btn btn-default btn-block py-2 rounded-0 bg-FF6400 text-light d-flex align-items-center justify-content-center" disabled={isLoading ?(true):("")} > {isLoading&& <CircularProgress size={18} color="inherit" className="mr-2" />} Sign Up</button>
                                <p className="mt-3">Already have an account? 
                                    <Link to="/auth">
                                        <span className="txt-ff6400 font-weight-bold cursor-pointer">Log In</span>
                                    </Link>
                                </p>
                                <p className="txt-ff6400 mt-4">Back to HOME</p>
                            </div> 
                        </Form>
                    )
                }
            </Formik> 
        </>
    )
}
