import api from '../../services/api';


export const updateFullName=(full_name, token, callback, errorCallback)=>{
    api
    .post('user/update', {
      full_name,
    },
    )
    .then((response) => callback(response))
    .catch(error => {
        console.log("Oops! Something went wrong ", error)
        errorCallback(error)
    });  
}



export const getProfileDetails=(token, callback, errorCallback)=>{
    api
    .get('user/profile',{})
    .then((response) => callback(response))
    .catch(error => {
        console.log("Oops! Something went wrong ", error)
        errorCallback(error)
    }); 
}