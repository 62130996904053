import React from 'react'
import { getManageSubscriptionLink } from '../../ApiConnections/Stripe/Stripe';
import {Link } from 'react-router-dom';

export default function BillingDetailsPlan(props) {
    const {subscriptionDetail, userDetails} = props; 
    const renderTextWrapper=(textLabel, textValue)=>{
        return( 
            <div className="col-lg-6 mb-3">
                {textLabel} : <span className="font-weight-bold">{textValue}</span>
            </div>
        )
    }
    const action4getSubscriptionLink=()=>{
        getManageSubscriptionLink(userDetails.token, getManageSubscriptionLinkCallback)
    }
    const getManageSubscriptionLinkCallback=(response)=>{
        if(response.status === 200){
            if(response.data.status === true){
                window.open(response.data.data.customer_portal_url, "_blank")
            }
        }
    }

    return (
        <div className="container my-5">
            <h3>Billing Details</h3>
            <hr />
            {
                userDetails.is_subscribed==="0" && userDetails.is_strip_connect ===1 && (
                    <div className="p-3 d-flex mb-4" style={{borderBottom: '3px solid #ac2e2e', color: '#9a0e0e', backgroundColor: '#e28d95'}}>
                        <i className="fas fa-exclamation-circle mr-3" style={{color: '#ac2e2e',fontSize: 24}} aria-hidden="true"></i>
                        Your subscription has been ended.
                    </div>

                )
            }

            <h4 className="txt-ff6400">{subscriptionDetail.plan_name}</h4>
            <div className="row">
                <div className="col-lg-8">
                    <h3 className="text-secondary my-3">
                        {userDetails.is_subscribed==="0" && userDetails.is_strip_connect ===1?("Your Previous Plan Details"):(
                            "Plan Details"
                        )}
                    </h3>
                    <div className="row">
                        <div className="col-lg-6 mb-3">
                            Price : <span className="font-weight-bold">{subscriptionDetail.currency_icon}{subscriptionDetail.amount}/year</span>
                        </div>
                        {renderTextWrapper('Interval', subscriptionDetail.interval)}
                        {renderTextWrapper('Renuewable Date', subscriptionDetail.end_date)}
                        {renderTextWrapper('Currency', subscriptionDetail.currency)}
                        {renderTextWrapper('Start Date', subscriptionDetail.start_date)}
                        {renderTextWrapper('Payment Method', 'Credit Card on file')}
                    </div>
                    
                </div>
                <div className="col-lg-4">
                    <div className="text-right mt-4 align-items-center justify-content-center">
                        <button type="button" className="btn btn-default px-5 py-2 rounded-0 bg-FF6400 text-light" onClick={()=>action4getSubscriptionLink()}>
                            {userDetails.is_subscribed==="0" && userDetails.is_strip_connect===1?("Renew Subscription"):("Manage Subscription")}
                        </button>
                        {userDetails.is_subscribed ==="0" && userDetails.is_strip_connect===1 &&
                            <Link className="text-light" to="/subscription/2">
                                <button className="outline-button px-5 py-2 mt-lg-4 rounded-0 "> &nbsp; Check New Plans &nbsp; </button>
                            </Link> 
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
