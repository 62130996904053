import { call, put, delay } from "redux-saga/effects"
import { getCurrencyFilterApi, getHighChartDataApi, getMarketCategoriesApi, getMarketSubCategoryApi, getQuarterFilterApi, getYearFilterApi } from "../requests/highChartRequests";

// for toast
import { toast } from 'react-toastify';
import { SET_CURRENCY_FILTER, SET_HIGHCHART_DATA, SET_MARKET_CATEGORIES, SET_MARKET_SUB_CATEGORIES, SET_QUARTER_FILTER, SET_YEAR_FILTER } from "../../action/highChartAction";
import { SET_BUTTON_LOADER } from "../../action/loaderAction";
toast.configure()  // toast-configuration method,

// Handle get market category
export function* handleGetMarketCategories() {
    yield delay(500)
    try {
        const response = yield call(getMarketCategoriesApi)
        // Set  data
        const marketData = response.data.data ?? undefined;
        if (marketData && response.data.status === true) {
            yield put({ type: SET_MARKET_CATEGORIES, marketData })
        } else {
            toast.error(response.data?.error_msg, {
                position: toast.POSITION.TOP_RIGHT
            })
        }
    } catch (error) {
        toast.error("Some error occured!, Please try after sometime.", {
            position: toast.POSITION.TOP_RIGHT
        })
    }
}

// Get Highchart Data
export function* handleGetHighChartData(action) {
    try {
        const response = yield call(getHighChartDataApi, action.data)
        const chartData = response.data.data ?? undefined;
        if (chartData && response.data.status === true) {
            yield put({ type: SET_HIGHCHART_DATA, data: chartData })
        } else {
            toast.error(response.data?.error_msg, {
                position: toast.POSITION.TOP_RIGHT
            })
        }
        yield put({ type: SET_BUTTON_LOADER, payload: false })
    } catch (error) {
        toast.error("Some error occured!, Please try after sometime.", {
            position: toast.POSITION.TOP_RIGHT
        })
    }
}

// Get Currency Filter Data
export function* handleGetCurrencyFilter(action) {
    try {
        const response = yield call(getCurrencyFilterApi, action.data)
        const currrencyData = response.data.data ?? undefined;
        if (currrencyData && response.data.status === true) {
            yield put({ type: SET_CURRENCY_FILTER, currrencyData })
        } else {
            toast.error(response.data?.error_msg, {
                position: toast.POSITION.TOP_RIGHT
            })
        }
        yield put({ type: SET_BUTTON_LOADER, payload: false })
    } catch (error) {
        toast.error("Some error occured!, Please try after sometime.", {
            position: toast.POSITION.TOP_RIGHT
        })
    }
}

//Get Year Filter Data
export function* handleGetYearFilter(action) {
    try {
        const response = yield call(getYearFilterApi, action.data)
        const yearData = response.data.data ?? undefined;
        if (yearData && response.data.status === true) {
            yield put({ type: SET_YEAR_FILTER, yearData })
        } else {
            toast.error(response.data?.error_msg, {
                position: toast.POSITION.TOP_RIGHT
            })
        }
        yield put({ type: SET_BUTTON_LOADER, payload: false })
    } catch (error) {
        toast.error("Some error occured!, Please try after sometime.", {
            position: toast.POSITION.TOP_RIGHT
        })
    }
}

//Get Quarter Filter Data
export function* handleGetQuarterFilter(action) {
    try {
        const response = yield call(getQuarterFilterApi, action.data)
        const quarterData = response.data.data ?? undefined;
        if (quarterData && response.data.status === true) {
            yield put({ type: SET_QUARTER_FILTER, quarterData })
        } else {
            toast.error(response.data?.error_msg, {
                position: toast.POSITION.TOP_RIGHT
            })
        }
        yield put({ type: SET_BUTTON_LOADER, payload: false })
    } catch (error) {
        toast.error("Some error occured!, Please try after sometime.", {
            position: toast.POSITION.TOP_RIGHT
        })
    }
}

//Handle get sub category
export function* handleGetMarketSubCategory() {
    yield delay(500)
    try {
        const response = yield call(getMarketSubCategoryApi)
        const listData = response.data.data ?? undefined;
        console.log(listData);
        if (listData && response.data.status === true) {
            let categoryList = [];
            categoryList[0] = listData[0]
            categoryList[1] = listData[2]
            categoryList[2] = listData[1]
            categoryList[3] = listData[3]
            yield put({ type: SET_MARKET_SUB_CATEGORIES, payload: categoryList })
        }
    } catch (error) {
        toast.error("Some error occured!, Please try after sometime.", {
            position: toast.POSITION.TOP_RIGHT
        })
    }
}
