import React from 'react'

export default function Footer() {
    return (
        <footer className="bg-FF6400 my_footer">
           <div className="container container-body container-padding">
                <div className="row py-4">
                    <div className="col-lg-6">
                        <p className="mb-0 text-light">Copyright &copy; {new Date().getFullYear()} - MACRO CODEX</p>
                    </div> 
                    <div className="col-lg-6">
                        <div className="row text-light mt-lg-0 mt-4 div-flex justify-right">
                            <div className="col-md-3"><div className="border-right border-white cursor-pointer">
                                <a className="text-light" href="https://macrocodex.com/list-of-markets/" target="_blank" rel="noreferrer">List of Markets</a></div></div>
                            <div className="col-md-3"><div className=" border-right border-white cursor-pointer">
                                <a className="text-light" href="https://macrocodex.com/terms-of-service/" target="_blank" rel="noreferrer">Terms&nbsp;of&nbsp;Services</a>
                            </div></div>
                            <div className="col-md-3"><div className="border-right border-white cursor-pointer">
                                <a className="text-light" href="https://macrocodex.com/privacy-policy/" target="_blank" rel="noreferrer"> Privacy Policy</a></div></div>
                            <div className="col-md-3"><div className="cursor-pointer">
                                <a className="text-light" href="https://macrocodex.com/risk-disclosure/" target="_blank" rel="noreferrer"> Risk Disclosure</a></div></div>
                        </div>
                    </div>
                </div>
           </div>
        </footer>
    )
}
