import { createStore, applyMiddleware } from "redux";
import reducers from "./index";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from 'redux-devtools-extension';
import { watcherSaga } from "./sagas/rootSaga";

const sagaMiddleware = createSagaMiddleware();

const middleware = [sagaMiddleware];

const store = createStore(reducers,{}, 
    composeWithDevTools(
        applyMiddleware(...middleware)
    )
)
    
sagaMiddleware.run(watcherSaga)

export default store;
