import React,{useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {Link, useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

//redux
import { userAuth } from '../../redux/action/userActions';
import { setButtonLoader } from '../../redux/action/loaderAction';

// COMPONENTS
import CircularProgress from '@mui/material/CircularProgress';

export default function LoginForm(props) {    
    const navigate = useNavigate();
    const dispatch = useDispatch()
    
    const userAuthStatus = useSelector((state) => state.user.authStatus)
    const authErrorRedux = useSelector((state) => state.user.error)
    const isLoading = useSelector((state)=>state.loader.buttonLoader)

    const [isPasswordVisible, setIsPasswordVisible] = useState(false)
    const[errorCode, setErrorCode] = useState("");
    const[errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
      if(authErrorRedux !== null){
        setErrorCode(authErrorRedux?.error_code)
        setErrorMessage(authErrorRedux?.error_msg)  
      }
    }, [authErrorRedux])
    
    useEffect(() => {
        if(userAuthStatus){
            navigate("/markets/bonds")  
        }
    }, [userAuthStatus, navigate])
    
    const LoginSchema = Yup.object().shape({
        email: Yup.string()
          .email("Invalid email address format")
          .required("Email is required"),
        password: Yup.string()
          .min(8, "Password must be 8 characters at minimum")
          .required("Password is required"),
    });

    return (
        <>
          <h3 className="mb-4">Log In</h3>
            <Formik
                initialValues={{ 
                    email: "", 
                    password: "" 
                }}
                validationSchema={LoginSchema}
                onSubmit={(values) => {
                    dispatch(userAuth(values));
                    dispatch(setButtonLoader(true)); 
                }}
            >
                {(props) => (
                    <Form>
                        <div className="form-group">
                            <label htmlFor="Email ID">Email</label>
                            <Field type="email" 
                                name="email"
                                className={`form-control ${props.touched.email && props.errors.email ? "is-invalid" : ""} ${errorCode ==='password' || errorCode === 'email' ? "is-invalid": ""} `}
                                placeholder="Enter your Email"
                            />
                            {errorCode === "email" ?(
                                <div className="invalid-feedback">
                                    <i className="fas fa-info-circle"></i> {errorMessage}
                                </div>
                            ) : (
                                <ErrorMessage
                                    component="div"
                                    name="email"
                                    className="invalid-feedback"
                                />
                            )}
                        </div>
                        <div className="form-group">
                            <label htmlFor="Password">Password</label>
                            <div className="positionRelative">
                                <Field 
                                    name="password" 
                                    type={isPasswordVisible?("text"):("password")} 
                                    className={`form-control ${props.touched.password && props.errors.password ? "is-invalid" : ""} ${errorCode ==='password' ? "is-invalid": ""} `} 
                                    placeholder="Enter Password"
                                />
                                {errorCode === "password" ?(
                                    <div className="invalid-feedback">
                                        <i className="fas fa-info-circle"></i> {errorMessage}
                                    </div>
                                ):(
                                    <ErrorMessage
                                        component="div"
                                        name="password"
                                        className="invalid-feedback"
                                    />
                                )}
                                <span className="eyeIconWrapper">
                                    {isPasswordVisible?(
                                        <i className="far fa-eye" onClick={()=>setIsPasswordVisible(!isPasswordVisible)}></i>
                                    ):(
                                        <i className="far fa-eye-slash" onClick={()=>setIsPasswordVisible(!isPasswordVisible)} ></i>
                                    )}
                                </span>
                            </div>
                        </div>
                        <div className="form-group d-flex justify-content-between">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{marginTop:7}} />
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                    Remember me
                                </label>
                            </div>
                            <Link to="/auth/forget-password">
                                <p className="txt-ff6400 cursor-pointer">Forget Password?</p>
                            </Link>
                        </div>
                        <div className="my-3 text-center">
                            <button type="submit" className="btn btn-default btn-block py-2 rounded-0 bg-FF6400 text-light d-flex align-items-center justify-content-center" disabled={isLoading ?(true):(null)} > {isLoading&& <CircularProgress size={18} color="inherit" className="mr-2" />} Log In</button>
                            
                                <p className="mt-3">Don’t have an account?  
                                    <Link to="/auth/sign-up">
                                        <span className="txt-ff6400 font-weight-bold cursor-pointer" > Sign Up</span>
                                    </Link>
                                </p>
                            
                            <p className="txt-ff6400 mt-4">Back to HOME</p>
                        </div> 
                    </Form> 
                )}
            </Formik>
        </>
    )
}
