import React, {useState, useEffect} from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js';
import {useParams} from 'react-router-dom'

import { getSelectPlanById } from '../../ApiConnections/Stripe/Stripe';

import CircularProgress from '@mui/material/CircularProgress';
import CheckoutForm from '../../Components/Stripe/CheckoutForm';
import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer/Footer';

const stripeTestPromise = loadStripe(`${process.env.STRIPE_PUBLIC_KEY}`)

export default function Subscription() {
    const params = useParams();
    
    
    const [planData, setPlanData] = useState()
    const [isPageLoading, setIsPageLoading] = useState(true)
    const[errorCode, setErrorCode] = useState("");
    const[errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
       getSelectPlanById(params.planId, gettingSeletedPlanCallback)
    }, [params.planId])


    const gettingSeletedPlanCallback=(response)=>{
        if(response.status === 200){
            if(response.data.status === true){
                setPlanData(response.data.data)
                setIsPageLoading(false)
            }else{
                setErrorCode(response.data.error_code);
                setErrorMessage(response.data.error_msg);
                setIsPageLoading(false)
            } 
        }
    }

    return (
        <>
            <Navbar/>
            <div className="my_stripe_testing">
                {isPageLoading?(
                    <div className="text-center my-5 d-flex align-items-center justify-content-center" style={{height:300}}>
                        <CircularProgress size={54} color="inherit" />
                    </div>
                )
                :
                (
        
                    errorCode === "plan_id" ? (
                        <div className="text-center">
                            <h2 className="mt-5 txt-ff6400"> <i className="fas fa-exclamation-triangle mr-2"></i> {errorMessage}</h2>
                            <button className="outline-button px-4 mt-4" >
                                <a href="https://macrocodex.com/subscriptions/" rel="noreferrer" target="_blank"> Check Plans</a>
                            </button>
                        </div>
                    )
                    :
                    (
                        <>
                            <div className="container mt-5">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h2>Subscribe</h2>
                                        <div className="d-flex mt-4 justify-content-between align-items-center">
                                            <h4>{planData && planData.plan_name}</h4>
                                            <h4 className="txt-ff6400">{planData && planData.currency_icon}&nbsp;{planData && planData.plan_amount}/{planData && planData.interval}</h4>
                                        </div>
                                        <h4 className="mt-4">Key faetures/services</h4>
                                        {planData && planData.features.map((item, key)=>
                                            <p className="mb-1" key={key}>{item}</p>
                                        )}
                                    </div>
                                    <div className="col-lg-6 pt-4-5 text-right">
                                        <button className="outline-button px-4 mt-4" >
                                            <a href="https://macrocodex.com/subscriptions/" rel="noreferrer" target="_blank"> Check Plans</a>
                                        </button>
                                    </div>
                                </div>
                                <p className=" my-4 font-weight-bold">Enter your card details</p>
                            </div>
                            <Elements stripe={stripeTestPromise}>
                                <CheckoutForm planId={params.planId} />
                            </Elements>
                        </>
                    )
                )}
            </div>
            <Footer/>
        </>
    )
}
