import React, {useEffect} from 'react'
import { useSelector } from 'react-redux'
import {Outlet, useNavigate } from 'react-router-dom';

// Components
import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer/Footer';

export default function ProfilePersonalInfo() {
    const navigate = useNavigate();
    // GET USER
    
    const userAuth = useSelector((state) => state.user.authStatus)
    
    useEffect(() => {
      if(!userAuth){
        navigate("/")
      }
    }, [navigate, userAuth])

    return (
      <>
        <Navbar />
        <div className="my_profile_personal_info container container-body container-padding my-5" style={{ height: 489 }}>
          <Outlet />
        </div>
        <Footer />
      </>
    )
}
