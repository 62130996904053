import { FORGOT_PASSWORD_SUCCESS, GET_USER, OTP_SENT_SUCCESS, SET_AUTH_ERROR, SET_EMAIL_FOR_OTP, SET_PASSWORD, SET_PROFILE_SUCCESS, SET_SINGUP_SUCCESS, SET_USER, VERIFY_OTP_SUCCESS} from "../../action/userActions";
import { changePasswordApi, changePasswordWithoutAuthApi, createUserAccountApi, forgetPasswordApi, getUser, updateFullNameApi, userAuth, verifyOTPApi, requestOtpApi } from "../requests/userRequests";
import {call, put} from "redux-saga/effects"

// for toast
import {toast} from 'react-toastify';
import { SET_BUTTON_LOADER } from "../../action/loaderAction";
toast.configure()  // toast-configuration method,


// For create User Account
export function* handlerCreateUserAccount(action) {
    try {
        const response = yield call(createUserAccountApi, action.userData)
        if(response.data.status === true){
            yield put({type: SET_SINGUP_SUCCESS, payload: true})
            yield put({ type: SET_BUTTON_LOADER, payload : false})
            yield put({ type: SET_EMAIL_FOR_OTP, payload : action.userData.email})
            toast.success(response.data?.message, {
                position: toast.POSITION.TOP_RIGHT
            })
        }else{
            toast.error(response.data?.error_msg, {
                position: toast.POSITION.TOP_RIGHT
            })
            const errorData = response?.data
            yield put({ type: SET_AUTH_ERROR, errorData})
        }
        yield put({ type: SET_BUTTON_LOADER, payload : false})
    } catch (error) {
        toast.error("Some error occured!, Please try after sometime.", {
            position: toast.POSITION.TOP_RIGHT
        })
    }
}


export function* handleVerifyOTP(action) {
    try {
        const response = yield call(verifyOTPApi, action.userData)
        if (response.data.status === true) {
            if(action.userData.otp_type === "S"){
                const userData = response.data.data?.user ?? undefined;
                yield put({ type: SET_USER, userData })
                localStorage.setItem('MacroCodexAuthToken', response.data?.data?.user?.token);
                toast.success(response.data?.message, {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
            yield put({type: VERIFY_OTP_SUCCESS, payload: true})
        } else {
            toast.error(response.data?.error_msg, {
                position: toast.POSITION.TOP_RIGHT
            })
            const errorData = response?.data
            yield put({ type: SET_AUTH_ERROR, errorData })
        }
        yield put({ type: SET_BUTTON_LOADER, payload : false})
    } catch (error) {
        toast.error("Some error occured!, Please try after sometime.", {
            position: toast.POSITION.TOP_RIGHT
        })
    }
}

//Request for OTP on email if user authStatus
export function* handleRequestOTP(action){
    try {
        const response = yield call(requestOtpApi)
        if (response?.data?.status === true) {
            toast.success(response?.data?.message, {
                position: toast.POSITION.TOP_RIGHT
            })
            yield put({ type: OTP_SENT_SUCCESS, payload : true})
            yield put({ type: SET_PASSWORD, payload : action.payload})
        } else {
            const errorData = response.data
            yield put({ type: SET_AUTH_ERROR, errorData})
            toast.error(response?.data?.error_msg, {
                position: toast.POSITION.TOP_RIGHT
            })
        }
        yield put({ type: SET_BUTTON_LOADER, payload : false})
    } catch (error) {
        toast.error("Some error occured!, Please try after sometime.", {
            position: toast.POSITION.TOP_RIGHT
        })
    }
}


// handle for forget Password
export function* handleForgetPassword(action) {
    try {
        const response = yield call(forgetPasswordApi, action.userData);
        if (response.data.status === true) {
            toast.success(response.data?.message, {
                position: toast.POSITION.TOP_RIGHT
            })
            yield put({type: FORGOT_PASSWORD_SUCCESS, payload: true})
            yield put({ type: SET_EMAIL_FOR_OTP, payload : action.userData.email})
        } else {
            const errorData = response?.data
            yield put({ type: SET_AUTH_ERROR, errorData})
            toast.error(response.data?.error_msg, {
                position: toast.POSITION.TOP_RIGHT
            })
        }
        yield put({ type: SET_BUTTON_LOADER, payload: false })
    } catch (error) {
        toast.error("Some error occured!, Please try after sometime.", {
            position: toast.POSITION.TOP_RIGHT
        })
    }
}



// For user authentication
export function* handleUserAuth(action) {
    try {
        const response = yield call(userAuth, action.authData);
        // Set user data
        const userData = response.data.data?.user ?? undefined; 
        if(userData && response.data.status === true) {
            yield put({ type: SET_USER, userData})
            localStorage.setItem('MacroCodexAuthToken', response.data.data.user?.token);
        } else {
            toast.error(response.data?.error_msg, {
                position: toast.POSITION.TOP_RIGHT
            })
            const errorData = response?.data
            yield put({ type: SET_AUTH_ERROR, errorData})
        }
        yield put({ type: SET_BUTTON_LOADER, payload : false})
    } catch (error) {
        toast.error("Invalid login credentials...!", {
            position: toast.POSITION.TOP_RIGHT
        })
    }
}

// Handle get user
export function* handleGetUser(action) {
    try {
        const response = yield call(getUser)
        // Set user data
        const userData = response.data.data.user ?? undefined;
        if(userData && response.data.status === true) {
            yield put({ type: SET_USER, userData})
        }
    } catch (error) {
        toast.error("Some error occured!, Please try after sometime.", {
            position: toast.POSITION.TOP_RIGHT
        })
    }
}

// Update Full Name
export function* handleUpdateFullName(action) {
    try {
        const response = yield call(updateFullNameApi, action);
        // console.log('getting updated name response', response)
        if (response.data.status === true) {
            toast.success(response.data?.message, {
                position: toast.POSITION.TOP_RIGHT
            })
            yield put({ type: GET_USER})
            yield put({type: SET_PROFILE_SUCCESS, payload: true})
        } else {
            toast.error(response.data?.error_msg, {
                position: toast.POSITION.TOP_RIGHT
            })
        }
        yield put({ type: SET_BUTTON_LOADER, payload: false })
    } catch (error) {
        toast.error("Some error occured!, Please try after sometime.", {
            position: toast.POSITION.TOP_RIGHT
        })
    }
}

// Update change password if user auth
export function* handleChangePassword(action) {
    try {
        const response = yield call(changePasswordApi, action.userData);
        if (response.data.status === true) {
            toast.success(response.data?.message, {
                position: toast.POSITION.TOP_RIGHT
            })
            yield put({type: SET_PROFILE_SUCCESS, payload: true})
        } else {
            toast.error(response.data?.error_msg, {
                position: toast.POSITION.TOP_RIGHT
            })
        }
        yield put({ type: SET_BUTTON_LOADER, payload: false })
    } catch (error) {
        toast.error("Some error occured!, Please try after sometime.", {
            position: toast.POSITION.TOP_RIGHT
        })
    }
}


// Update change password without auth
export function* handleChangePasswordWithoutAuth(action) {
    try {
        const response = yield call(changePasswordWithoutAuthApi, action.userData);
        if (response.data.status === true) {
            toast.success(response.data?.message, {
                position: toast.POSITION.TOP_RIGHT
            })
            yield put({type: FORGOT_PASSWORD_SUCCESS, payload: true})
        } else {
            toast.error(response.data?.error_msg, {
                position: toast.POSITION.TOP_RIGHT
            })
        }
        yield put({ type: SET_BUTTON_LOADER, payload: false })
    } catch (error) {
        toast.error("Some error occured!, Please try after sometime.", {
            position: toast.POSITION.TOP_RIGHT
        })
    }
}
