import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, Link } from 'react-router-dom';

//action redux
import { useSelector, useDispatch } from 'react-redux'
import { unsetUser } from '../../redux/action/userActions';
import { getMarketCategories } from '../../redux/action/highChartAction';

import './Navbar.css'

export default function Navbar() {
    const navbarRef = useRef(null)
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const [marketCategories, setMarketCategories] = useState([])

    const userAuth = useSelector((state) => state.user.authStatus)
    const userDetails = useSelector((state) => state.user.userDetails)
    const marketCategorData = useSelector((state) => state.highchart.marketCategories)

    useEffect(() => {
        if (userAuth) {
            dispatch(getMarketCategories())
        }
    }, [dispatch, userAuth])

    useEffect(() => {
        setMarketCategories(marketCategorData)
    }, [marketCategorData])
    

    const action4Logout = () => {
        localStorage.removeItem('MacroCodexAuthToken');
        dispatch(unsetUser());
        navigate("/")
    }

    const closeNavbar = () =>{
        navbarRef.current.click()
    }

    return (
        <>
            {/* Navbar for large screen */}
            <div className="navbar-container container-fluid navbar_large px-5">
                <nav className="navbar navbar-expand-lg MacroCodexHomeNavBar navbar-light justify-spaceBetween px-5">
                    <Link className="navbar-brand" to="/dashboard">
                        <img src="/Assets/MacroCodexWordIcon.png" alt="" />
                    </Link> 
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="container navbar_container mr-0 pr-0">

                        <div className="collapse navbar-collapse navbar_list" id="navbarSupportedContent">
                            <ul className="navbar-nav ml-auto">
                                {/* <li className="nav-item pr-32">
                                    <Link className="nav-link " to="/dashboard">Dashboard <span className="sr-only">(current)</span></Link>
                                </li> */}
                                <li className="nav-item dropdown pr-32">
                                    <ul className="navbar-nav">
                                        <li className="nav-item dropdown">
                                            <span className="nav-link dropdown-toggle cursor-pointer" href="#" data-toggle="dropdown">Markets</span>
                                            <ul className="dropdown-menu">
                                                {marketCategories.map((item, id) => {
                                                    return <>
                                                        <li key={item.id}><Link className="dropdown-item" to={`/markets/${item.slug}`}>{item.category}</Link></li>
                                                        <div className="dropdown-divider"></div>
                                                    </>
                                                })}
                                            </ul>

                                        </li>
                                    </ul>
                                </li>

                                {/* <li className="nav-item dropdown pr-32">
                                    <span className="nav-link dropdown-toggle cursor-pointer" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Charts
                                    </span>
                                </li> */}

                                {/* <li className="nav-item pr-32">
                                    <a className="nav-link" href="https://macrocodex.com/subscriptions/" target="_blank"  rel="noreferrer"> Plans </a>
                                </li> */}
                                <li className="nav-item pr-32">
                                    <a className="nav-link" href="https://macrocodex.com/faq/" target="_blank"  rel="noreferrer" >FAQ's</a>
                                </li>
                                <li className="nav-item pr-32">
                                    <a className="nav-link " href="https://macrocodex.com/contact/" target="_blank"  rel="noreferrer">Contact Us</a>
                                </li>
                            </ul>
                        </div>
                        <div className="userprofile1">
                            <div className="my-lg-0 ">
                                <div className="d-flex">
                                    <span className="nav-link dropdown-toggle  ml-2 cursor-pointer nav-user-name" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{userDetails.full_name}</span>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown" style={{ right: 2, left: 'auto' }}>
                                        <Link to="/profileInfo" className="dropdown-item cursor-pointer mb-1" >My profile</Link>
                                        <Link to="/billing" className="dropdown-item cursor-pointer mb-1"  >Billing</Link>
                                        <p className="dropdown-item cursor-pointer mb-1" onClick={() => action4Logout()} >Logout</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>

            {/* Navbar for small screen */}
            <div className="navbar-container navbar_small">
                <nav className="navbar navbar-expand-lg MacroCodexHomeNavBar navbar-light justify-spaceBetween">
                    <Link className="navbar-brand" to="/dashboard">
                        <img src="/Assets/MacroCodexWordIcon.png" alt="" />
                    </Link> 
                    <button ref={navbarRef} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContentMobile" aria-controls="navbarSupportedContentMobile" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="container navbar_container ">
                        <div className="collapse navbar-collapse navbar_list" id="navbarSupportedContentMobile">
                            <ul className="navbar-nav ml-auto">
                                {/* <li className="nav-item pr-32">
                                    <Link onClick={()=>closeNavbar()} className="nav-link " to="/dashboard">Dashboard <span className="sr-only">(current)</span></Link>
                                </li> */}
                                <li className="nav-item dropdown pr-32">
                                    <ul className="navbar-nav">
                                        <li className="nav-item dropdown">
                                            <span className="nav-link dropdown-toggle cursor-pointer" data-toggle="dropdown">Markets</span>
                                            <ul className="dropdown-menu" onClick={(e) => e.stopPropagation()}>
                                                {marketCategories.map((item, id) => {
                                                    return <>
                                                        <li key={item.id}><Link onClick={() => closeNavbar()} className="dropdown-item" to={`/markets/${item.slug}`}>{item.category}</Link></li>
                                                        <div className="dropdown-divider"></div>
                                                    </>
                                                })}
                                            </ul>
                                        </li>
                                    </ul>
                                </li>

                                {/* <li className="nav-item dropdown pr-32">
                                    <span className="nav-link dropdown-toggle cursor-pointer" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Charts
                                    </span>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    </div>
                                </li> */}

                                {/* <li className="nav-item pr-32">
                                    <a className="nav-link" href="https://macrocodex.com/subscriptions/" target="_blank"  rel="noreferrer"> Plans </a>
                                </li> */}
                                <li className="nav-item pr-32">
                                    <a className="nav-link" href="https://macrocodex.com/faq/" target="_blank"  rel="noreferrer" >FAQ's</a>
                                </li>
                                <li className="nav-item pr-32">
                                    <a className="nav-link " href="https://macrocodex.com/contact/" target="_blank"  rel="noreferrer">Contact Us</a>
                                </li>
                                <div className="userprofile2">
                                    <li className="nav-item pr-32">
                                        <Link to="/profileInfo" className="nav-link cursor-pointer mb-1" >My profile</Link>
                                    </li>
                                    <li className="nav-item pr-32">
                                        <Link to="/billing" className="nav-link cursor-pointer mb-1"  >Billing</Link>
                                    </li>
                                    <li className="nav-item pr-32">
                                        <p className="nav-link cursor-pointer mb-1" onClick={() => action4Logout()} >Logout</p>
                                    </li>
                                </div>
                            </ul>
                        </div>
                    </div>
                </nav>

            </div>
        </>
    )
}
