import React, {useState, useEffect} from 'react'
import {useNavigate, useParams} from 'react-router-dom'


//redux action
import { useDispatch, useSelector} from 'react-redux';
import { setEmailForOtp, verifyOTP, verifyOTPSuccess } from '../../redux/action/userActions';
import { setButtonLoader } from '../../redux/action/loaderAction';

import CircularProgress from '@mui/material/CircularProgress';

export default function VerifyEmail(props) {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const Params = useParams()

    const [otpType, setotpType] = useState("")
    useEffect(() => {
        if(Params.type === "signup") {
            setotpType("S");
        } else if(Params.type === "forget") {
            setotpType("F");
        }
    }, [Params])
    
    const[input1, setInput1] = useState("");
    const[input2, setInput2] = useState("");
    const[input3, setInput3] = useState("");
    const[input4, setInput4] = useState("");
    const[input5, setInput5] = useState("");
    const[input6, setInput6] = useState("");

    const isLoading = useSelector((state)=>state.loader.buttonLoader)
    const verifyOTPStatus = useSelector((state)=>state.user?.verifyOTPSuccess)
    const otpEmail = useSelector((state)=>state.user?.otpEmail)

    const[Timerseconds, setTimerseconds] = useState("60");

    useEffect(() => {
        if (Timerseconds > 0) {
            let currentTimer = Timerseconds
            setTimeout(() => setTimerseconds(currentTimer-1), 1000);
        } else {
            setTimerseconds("0")
        }
    }, [Timerseconds])

    useEffect(() => {
        if (verifyOTPStatus === true) {
            if (otpType === "signup") {
                navigate("/markets/bonds")
                dispatch(setEmailForOtp(null))
            } else if (otpType === "F") {
                navigate("/auth/reset-password")
            }
        }
    }, [verifyOTPStatus, otpType, navigate, dispatch])
    
    const action4Verify=()=>{
        const verifyOTPData = {
            email : otpEmail,
            otp: input1+input2+input3+input4+input5+input6,
            otp_type: otpType
        }
        dispatch(verifyOTP(verifyOTPData))
        dispatch(setButtonLoader(true));
        dispatch(verifyOTPSuccess(false))
    }

    const onBackKeyPress=(e)=>{
        if(e.keyCode === 8 || e.keyCode === 37){
            e.target.previousSibling.focus();
        } else {
            e.target.nextSibling.focus();
        }
    }
    
    return (
        <>
            <h3 className="mb-4">Verify Email </h3>
            <p>
                Please enter 6 digit OTP sent on your registered Email
            </p>
            <div className="form-group">
                <div className="d-flex mt-4">
                    <input 
                        type="tel" 
                        className={"form-control mx-2 text-center"}  
                        maxLength="1" 
                        onChange={(e)=> {setInput1(e.target.value)}}
                        onKeyUp={(e)=>onBackKeyPress(e)}
                    />
                    <input type="tel" 
                        className={"form-control mx-2 text-center"} 
                        maxLength="1" 
                        onChange={(e)=> {setInput2(e.target.value);}}
                        onKeyUp={(e)=>onBackKeyPress(e)}
                    />
                    <input type="tel" 
                        className={"form-control mx-2 text-center"} 
                        maxLength="1" 
                        onChange={(e)=> {setInput3(e.target.value)}}
                        onKeyUp={(e)=>onBackKeyPress(e)}
                    />
                    <input type="tel" 
                        className={"form-control mx-2 text-center"} 
                        maxLength="1" 
                        onChange={(e)=> {setInput4(e.target.value)}}
                        onKeyUp={(e)=>onBackKeyPress(e)}
                    />
                    <input type="tel" 
                        className={"form-control mx-2 text-center"} 
                        maxLength="1" 
                        onChange={(e)=> {setInput5(e.target.value)}}
                        onKeyUp={(e)=>onBackKeyPress(e)}
                    />
                    <input type="tel" 
                        className={"form-control mx-2 text-center"} 
                        maxLength="1" 
                        onChange={(e)=> {setInput6(e.target.value)}}
                        onKeyUp={(e)=>onBackKeyPress(e)}
                    />
                </div>
            </div>
            <div className="form-group d-flex justify-content-between mx-2">
                <p className="txt-316fe8 cursor-pointer">Resend</p>
                <p>{Timerseconds} Sec</p>
            </div>
            <div className="my-3 text-center">
                <button type="button" className="btn btn-default btn-block py-2 rounded-0 bg-FF6400 text-light d-flex align-items-center justify-content-center" onClick={()=>action4Verify()} disabled={isLoading ?(true):("")} > {isLoading&& <CircularProgress size={18} color="inherit" className="mr-2" />} Verify</button>
            </div> 
        </>
    )
}
