import api from "../../../services/api";

// GET Market Categories
export const getMarketCategoriesApi = () =>{
    return api.get('getMarketCategories', {
    })
}


// Get Highchart Data
export const getHighChartDataApi = async(data)=>{
    let apiUrl = `datafile/getFileData?directory=${data.directory}&currency=${data.currency}&year=${data.year}&quarter=${data.quarter}`;
    return api.get(apiUrl)
}

// Get Currency Filter data
export const getCurrencyFilterApi = async(data) =>{
    return api.get(`getCurrencies?marketCategory=${data.category}&marketSubCategory=${data.subCategory}`)
}

// Get Year Filter data
export const getYearFilterApi = async(data) =>{
   return api.get(`getYears?marketCategory=${data.category}&marketSubCategory=${data.subCategory}&currency=${data.currency}`)
}


// Get Quarter Filter data
export const getQuarterFilterApi = async (data) => {
    return api.get(`getQuarters?marketCategory=${data.category}&marketSubCategory=${data.subCategory}&currency=${data.currency}&year=${data.year}`, {
    })
}

// get market sub categories
export const getMarketSubCategoryApi = async (data) => {
    return api.get(`getSubcategories`)
}
