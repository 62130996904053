import React from 'react'

import styles from "./Loader.module.css";

export default function Loader() {
    return (
        <>
            <div className={`d-flex align-items-center justify-content-center ${styles.loader_container}`}>
                <div className={`spinner-grow ${styles.loader_spinner}`} role="status">
                    {/* <span className="visually-hidden">Loading...</span> */}
                </div>
            </div>
        </>
    )
}
