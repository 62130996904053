import React, {useEffect} from 'react'
import { useSelector} from 'react-redux'
import {Outlet, useNavigate } from 'react-router-dom';

export default function Auth() {
    const navigate = useNavigate();

    const userAuth = useSelector((state) => state.user.authStatus)

    useEffect(() => {
        if(userAuth){
            navigate("/markets/bonds")
        }
    }, [userAuth, navigate])

    return (
        <>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-lg-8 d-none d-sm-block">
                        <div>
                            <img src="/Assets/AuthBannerImg.png" className="img-fluid" alt="" />
                        </div>
                    </div>
                    <div className="col-lg-4 pt-2">
                        <div className="mb-3">
                            <img src="Assets/MacroCodexWordIcon.png" width="65" alt="" />
                        </div>
                        <Outlet/>
                    </div>
                </div>
            </div>
        </>
    )
}
