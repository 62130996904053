import { takeLatest, all } from "redux-saga/effects";
import { GET_CURRENCY_FILTER, GET_HIGHCHART_DATA, GET_MARKET_CATEGORIES, GET_MARKET_SUB_CATEGORIES, GET_QUARTER_FILTER, GET_YEAR_FILTER } from "../action/highChartAction";
import { CHANGE_PASSWORD, CHANGE_PASSWORD_NO_AUTH, CREATE_USER_ACCOUNT, FORGOT_PASSWORD, GET_USER, REQUEST_OTP, UPDATE_FULL_NAME, USER_AUTH, VERIFY_OTP } from "../action/userActions";
import { handleGetCurrencyFilter, handleGetHighChartData, handleGetMarketCategories, handleGetMarketSubCategory, handleGetQuarterFilter, handleGetYearFilter } from "./handlers/highChartHandlers";
import { handleChangePassword, handleChangePasswordWithoutAuth, handleForgetPassword, handleGetUser, handlerCreateUserAccount, handleRequestOTP, handleUpdateFullName, handleUserAuth, handleVerifyOTP } from "./handlers/userHandlers";

export function* watcherSaga() {
    yield all([
        // User Handlers
        yield takeLatest(CREATE_USER_ACCOUNT, handlerCreateUserAccount),
        yield takeLatest(USER_AUTH, handleUserAuth),
        yield takeLatest(GET_USER, handleGetUser),
        yield takeLatest(UPDATE_FULL_NAME, handleUpdateFullName),
        yield takeLatest(CHANGE_PASSWORD, handleChangePassword),
        yield takeLatest(CHANGE_PASSWORD_NO_AUTH, handleChangePasswordWithoutAuth),
        yield takeLatest(REQUEST_OTP, handleRequestOTP),
        yield takeLatest(VERIFY_OTP, handleVerifyOTP),
        yield takeLatest(FORGOT_PASSWORD, handleForgetPassword),

        //HighChart Handlers
        yield takeLatest(GET_MARKET_CATEGORIES, handleGetMarketCategories),
        yield takeLatest(GET_HIGHCHART_DATA, handleGetHighChartData),
        yield takeLatest(GET_CURRENCY_FILTER, handleGetCurrencyFilter),
        yield takeLatest(GET_YEAR_FILTER, handleGetYearFilter),
        yield takeLatest(GET_QUARTER_FILTER, handleGetQuarterFilter),
        yield takeLatest(GET_MARKET_SUB_CATEGORIES, handleGetMarketSubCategory),

    ])
}