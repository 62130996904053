import { SET_CURRENCY_FILTER, SET_HIGHCHART_DATA, SET_MARKET_CATEGORIES, SET_MARKET_SUB_CATEGORIES, SET_QUARTER_FILTER, SET_YEAR_FILTER, UNSET_CHART_DATA, UNSET_CURRENCY_FILTER, UNSET_QUARTER_FILTER, UNSET_YEAR_FILTER } from "../action/highChartAction"

const initialState = {
    marketCategories: [],
    marketSubCategories: [],
    highchartData: [],
    currencyFilter: [],
    yearFilter: [],
    quarterFilter: []
}


export const highChartReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MARKET_CATEGORIES:
            return {
                ...state,
                marketCategories: action.marketData
            }
        case SET_HIGHCHART_DATA:
            return {
                ...state,
                highchartData: action.data
            }
        case UNSET_CHART_DATA:
            return {
                ...state,
                highchartData: []
            }
        case SET_CURRENCY_FILTER:
            return {
                ...state,
                currencyFilter: action.currrencyData
            }
        case UNSET_CURRENCY_FILTER:
            return {
                ...state,
                currencyFilter: []
            }
        case SET_YEAR_FILTER:
            return {
                ...state,
                yearFilter: action.yearData
            }
        case UNSET_YEAR_FILTER:
            return {
                ...state,
                yearFilter: []
            }
        case SET_QUARTER_FILTER:
            return {
                ...state,
                quarterFilter: action.quarterData
            }
        case UNSET_QUARTER_FILTER:
            return {
                ...state,
                quarterFilter: []
            }
        case SET_MARKET_SUB_CATEGORIES:
            return {
                ...state,
                marketSubCategories: action.payload
            }
        default: return state
    }
}