import React,{useState, useEffect} from 'react'
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from 'react-router-dom';

//redux action
import { useDispatch, useSelector} from 'react-redux';
import { forgetPasswordSucess, forgotPassword } from '../../redux/action/userActions';
import { setButtonLoader } from '../../redux/action/loaderAction';

import CircularProgress from '@mui/material/CircularProgress';

export default function ForgetForm(props) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const[errorCode, setErrorCode] = useState("");
    const[errorMessage, setErrorMessage] = useState("");
    
    const isLoading = useSelector((state)=>state.loader.buttonLoader)
    const authErrorRedux = useSelector((state) => state.user.error)
    const isForgetPasswordSucess = useSelector((state)=> state.user.forgetPasswordSucess)

    useEffect(() => {
        if (isForgetPasswordSucess === true) {
            navigate("/auth/verify-email/forget")
            dispatch(forgetPasswordSucess(false))
        }
    }, [dispatch, navigate, isForgetPasswordSucess])

    useEffect(() => {
        if (authErrorRedux !== null) {
            setErrorCode(authErrorRedux?.error_code)
            setErrorMessage(authErrorRedux?.error_msg)
        }
    }, [authErrorRedux])
    
    const ForgetPasswordSchema = Yup.object().shape({
        email: Yup.string()
            .email("Invalid email address format")
            .required("Email is required"),
    });

    return (
        <>
            <h3 className="mb-4">Forgot Password</h3>
            <p>
                Please enter your registered email address. <br />We will send an OTP for user varification.
            </p>
            <Formik
                initialValues={{ 
                    email: "", 
                }}
                validationSchema={ForgetPasswordSchema}
                onSubmit={(values) => {
                    const forgetPasswordData = {
                        email : values.email
                    }
                    dispatch(forgotPassword(forgetPasswordData))
                    dispatch(setButtonLoader(true));
                    dispatch(forgetPasswordSucess(false))
                }}
            >
                {(props) => (
                    <Form>
                        <div className="form-group">
                            <label htmlFor="Email ID">Email</label>
                            <Field 
                                type="text"
                                name="email"
                                className={`form-control ${props.touched.email && props.errors.email ? "is-invalid" : ""} ${errorCode ==='email' ? "is-invalid": ""} `} 
                                placeholder="Enter your Email"
                            />
                            {errorCode === "email"?(
                                <div className="invalid-feedback">
                                    <i className="fas fa-info-circle"></i> {errorMessage}
                                </div>
                            ) : (
                                <ErrorMessage
                                    component="div"
                                    name="email"
                                    className="invalid-feedback"
                                />
                            )}
                        </div>
                        <div className="my-4 text-center">
                            <button type="submit" className="btn btn-default btn-block py-2 rounded-0 bg-FF6400 text-light" disabled={isLoading ?(true):("")} > {isLoading&& <CircularProgress size={18} color="inherit" className="mr-2" />} Send</button>
                            <Link to="/auth">
                                <p className="outline-button cursor-pointer mt-3">Back</p>
                            </Link>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}
