import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { getSubscriptionDetails } from '../../ApiConnections/Stripe/Stripe'

import BillingDetails from '../../Components/Billing/BillingDetails'
import BillingDetailsPlan from '../../Components/Billing/BillingDetailsPlan'
import CircularProgress from '@mui/material/CircularProgress';

export default function Billing() {
    // Get user
    const userDetails = useSelector((state) => state.user.userDetails)

    const [subscriptionDetail, setSubscriptionDetails] = useState("")
    const [isPageLoading, setIsPageLoading] = useState(true)

    useEffect(() => {
        if (userDetails.is_subscribed === "1") {
            setIsPageLoading(true)
            getSubscriptionDetails(userDetails.token, getSubscriptionDetailsCallback)
        } else if (userDetails.is_subscribed === "0" && userDetails.is_strip_connect === 1) {
            setIsPageLoading(true)
            getSubscriptionDetails(userDetails.token, getSubscriptionDetailsCallback)
        } else {
            setIsPageLoading(false)
        }
    }, [userDetails])

    const getSubscriptionDetailsCallback = (response) => {
        if (response.status === 200) {
            setSubscriptionDetails(response.data.data)
        }
        setIsPageLoading(false)
    }

    return (
        <>
            <div className="my_billing">
                {isPageLoading ? (
                    <div className="text-center my-5 d-flex align-items-center justify-content-center" style={{ height: 300 }}>
                        <CircularProgress size={54} color="inherit" />
                    </div>
                ) : (
                    <>
                        {userDetails.is_subscribed === "0" && userDetails.is_strip_connect === 0 &&
                            <BillingDetails />
                        }

                        {userDetails.is_subscribed === "0" && userDetails.is_strip_connect === 1 &&
                            <BillingDetailsPlan subscriptionDetail={subscriptionDetail} userDetails={userDetails} />
                        }

                        {userDetails.is_subscribed === "1" &&
                            <BillingDetailsPlan subscriptionDetail={subscriptionDetail} userDetails={userDetails} />
                        }
                    </>
                )}
            </div>
        </>
    )
}
