import { SET_BUTTON_LOADER } from "../action/loaderAction";


const initialState = {
    buttonLoader: false,
}

export const loaderReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_BUTTON_LOADER:
            return {
                ...state,
                buttonLoader: action.payload
            };
        default:
            return state;
    }
}